import _ from 'lodash';
import {
  takeLeading,
} from 'redux-saga/effects';
import {api} from '../../api';
import { sagaFactory, zedApiResponseDataTransformer, zedApiResponseValidator } from '../../helpers/sagaHelpers';
import { EsimActions } from './EsimRedux';
import * as T from '../../types/api';

const assignNumberRequest = sagaFactory<T.AssignNumberResponse>({
  restActions: EsimActions.assignNumber,
  apiRequest: api.assignNumber,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const changeCallForwardingRequest = sagaFactory<T.ChangeCallForwardingResponse>({
  restActions: EsimActions.changeCallForwarding,
  apiRequest: api.changeCallForwarding,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const changeRenewStatusRequest = sagaFactory<T.ChangeRenewStatusResponse>({
  restActions: EsimActions.changeRenewStatus,
  apiRequest: api.changeRenewStatus,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const getSmsRequest = sagaFactory<T.GetSmsResponse>({
  restActions: EsimActions.getSms,
  apiRequest: api.getSms,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const listCountriesRequest = sagaFactory<T.ListCountriesEsimResponse>({
  restActions: EsimActions.listCountry,
  apiRequest: api.listCountry,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const listNumbersRequest = sagaFactory<T.ListNumbersResponse>({
  restActions: EsimActions.listNumbers,
  apiRequest: api.listNumbers,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const newNumberRequest = sagaFactory<T.NewNumberResponse>({
  restActions: EsimActions.newNumber,
  apiRequest: api.newNumber,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const sendCodeRequest = sagaFactory<T.SendCodeResponse>({
  restActions: EsimActions.sendCode,
  apiRequest: api.sendCode,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const topUpRequest = sagaFactory<T.TopUpResponse>({
  restActions: EsimActions.topUp,
  apiRequest: api.topUp,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const unassignNumberRequest = sagaFactory<T.UnassignNumberResponse>({
  restActions: EsimActions.unassignNumber,
  apiRequest: api.unassignNumber,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const verifyCodeRequest = sagaFactory<T.VerifyCodeResponse>({
  restActions: EsimActions.verifyCode,
  apiRequest: api.verifyCode,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

export function* EsimSaga() {
  yield* [
    takeLeading(EsimActions.assignNumber.request.type,assignNumberRequest),
    takeLeading(EsimActions.changeCallForwarding.request.type,changeCallForwardingRequest),
    takeLeading(EsimActions.changeRenewStatus.request.type,changeRenewStatusRequest),
    takeLeading(EsimActions.getSms.request.type,getSmsRequest),
    takeLeading(EsimActions.listCountry.request.type, listCountriesRequest),
    takeLeading(EsimActions.listNumbers.request.type, listNumbersRequest),
    takeLeading(EsimActions.newNumber.request.type, newNumberRequest),
    takeLeading(EsimActions.sendCode.request.type, sendCodeRequest),
    takeLeading(EsimActions.topUp.request.type, topUpRequest),
    takeLeading(EsimActions.unassignNumber.request.type, unassignNumberRequest),
    takeLeading(EsimActions.verifyCode.request.type, verifyCodeRequest),
  ];
}