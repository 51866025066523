import { ApisauceInstance } from "apisauce";
import { addAgentIdToRequestParams } from "./addAgentId";
// import { CodeBud } from "@appklaar/codebud";

const API_ROUTES_THAT_REQUIRE_AGENT_ID = new Set([
  // "white_label_get_page",
  // "white_label_save_page",
  "/blog/list",
  "/user/register_email",
  "/user/login",
  "/user/register",
  "/user/confirm_new_email"
]);

export const addAxiosResponseInterceptor = (sauce: ApisauceInstance) => {
  sauce.axiosInstance.interceptors.request.use(
    (config) => {
      // CodeBud.captureEvent("config", {config});

      if (config.headers?.["x-before-transform-request"]) {
        // CodeBud.captureEvent('Axios requestInterceptor', {
        //   method: config.method,
        //   data: config.data, 
        //   params: config.params, 
        //   headers: config.headers
        // });
        // console.log(1, config.headers["x-before-transform-request"])
        delete config.headers["x-before-transform-request"];
        // console.log(2, config.headers["x-before-transform-request"])
        
        if (config.url && API_ROUTES_THAT_REQUIRE_AGENT_ID.has(config.url)) {
          if (config.method === "GET")
            config.params = addAgentIdToRequestParams(config.params);
          else 
            config.data = addAgentIdToRequestParams(config.data);
        }
      }
      
      return config;
    }
  );

  sauce.axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const response = error.response;
      const originalRequest = error.config;

      // Redirect to zed-pay API
      if (
        originalRequest &&
        response?.status === 307 &&
        response?.headers &&
        response.headers["x-location"]
      ) {
        // console.log("originalRequest", originalRequest);

        originalRequest.headers["Authorization"] =
          response.headers["x-auth-token"] ?? "";
        originalRequest.baseURL = response.headers["x-location"];
        originalRequest.url = "";

        return sauce.axiosInstance(originalRequest);
      }

      return Promise.reject(error);
    }
  );
};
