import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  BoxWBorder,
  FormContainer,
  FormTitle,
  OtpInput,
  OtpInputsContainer,
  SmallLink,
  SmallText,
} from "../LoginPageStyles";
import i18n from "../../../i18n/i18n";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../logic/user/UserSelectors";
import { UserActions } from "../../../logic/user/UserRedux";
import { showToast } from "../../../helpers/alertService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "@material-ui/core";
import { SupportButton } from "../../../components/SupportButton";

const OtpBlock = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const {
    userEmail,
    confirmNewEmail: { fetching: fetchingConfirmNewEmail },
  } = useSelector(userSelector);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value.length === 1 && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      } else if (value.length === 0 && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  // после удаления клавишей "backspace" каретка перекидывается на предыдущий кружочек
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  // можно вставлять скоппированый проль
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData?.getData("text/plain").slice(0, 6) || "";

    const newOtp = pasteData
      .split("")
      .map((digit, index) => (index < otp.length ? digit : ""))
      .concat(otp.slice(pasteData.length));

    setOtp(newOtp);
  };

  // проверка совпадения (не знаю, как точно оно будет работать, но пока оставлю так)
  useEffect(() => {
    const otpStr = otp.join().replace(/\D/g, "");
    if (otpStr.length === 6) {
      dispatch(
        UserActions.confirmNewEmail.request({
          email: userEmail as string,
          code: otpStr,
          successCallback: () => {
            navigate("/register");
          },
          wrongCodeCallback: () => {
            // setError(true);
            showToast({
              title: t("wrongCode"),
              info: t("verificationCodeNotCorrect"),
              type: "error",
            });
          },
        })
      );
    }
  }, [otp]);

  return (
    <>
      <FormContainer>
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <BoxWBorder>
            <img
              style={{ width: "30px", height: "30px" }}
              src="/images/flags/gb.svg"
              alt="aas"
              onClick={() => i18n.changeLanguage("de")}
            />
          </BoxWBorder>
          <SupportButton />
        </Box>
        <FormTitle>{t("otp.confirmation")}</FormTitle>
        <SmallText>
          {t("otp.enterTheCode")}
          <SmallLink>{userEmail ?? "email"}</SmallLink>
        </SmallText>
        <OtpInputsContainer>
          {otp.map((digit, index) => (
            <OtpInput
              key={index}
              type="text"
              value={digit}
              maxLength={1}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              placeholder="X"
              onPaste={handlePaste}
              ref={(ref) => (inputRefs.current[index] = ref!)}
            />
          ))}
        </OtpInputsContainer>
      </FormContainer>
    </>
  );
};
export default OtpBlock;
