export function parseHtmlToResults(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    const headers = doc.querySelectorAll('h4');
    const paragraphs = doc.querySelectorAll('p');
  
    if (headers.length !== paragraphs.length) {
      throw new Error('HTML structure is not as expected');
    }
  
    const results = Array.from(headers).map((header, index) => ({
      question: header.textContent.trim(),
      answer: paragraphs[index].textContent.trim()
    }));
  
    return results;
  }