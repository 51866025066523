import {
  useRoutes,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Page404 } from "./Pages/Page404";
import { LoginPage } from "./Pages/Login";
import { RegisterPage } from "./Pages/Register";
import { ResetPasswordPage } from "./Pages/ResetPassword";
import { NewPassword } from "./Pages/NewPassword";
import { CardsOperationsPage } from "./Pages/Payments/CardsOperations";
import { useSelector } from "react-redux";
import { userSelector } from "./logic/user/UserSelectors";
import { Wrapper } from "./AppStyles";
import Layout from "./Pages/Layout";
import Main from "./Pages/Main";
import Payments from "./Pages/Payments/PaymentsPage";
import Verification from "./Pages/Verification";
import CardsPage from "./Pages/Cards";
import Profile from "./Pages/Profile";
import SupportPage from "./Pages/Support";
import Faq from "./Pages/Faq";
import About from "./Pages/About";
import { animateScroll } from "react-scroll";
import { FC, ReactNode, useEffect } from "react";
import MarketPage from "./Pages/Markets/Markets";
import NFTItemPage from "./Pages/NFT/NFTItemPage/NFTItemPage";
import NFTCatalogPage from "./Pages/NFT/NFTCatalogPage/NFTCatalogPage";
import CreateNFT from "./Pages/NFT/CreateNFT/CreateNFT";
import NFTPage from "./Pages/NFT/NFTHomePage/NFTHomePage";
import NFTProfile from "./Pages/NFT/NFTProfilePage/NFTProfile";
import CreateNFTCollectionPage from "./Pages/NFT/CreateNFTCollection/CreateNFTCollection";
import NFTMyCollectionPage from "./Pages/NFT/NFTMyCollectionPage/NFTMyCollectionPage";
import NFTCollectionItemPage from "./Pages/NFT/NFTCollectionItemPage/NFTCollectionItemPage";
import NFTPlansPage from "./Pages/NFT/NFTPlansPage/NFTPlansPage";

type ProtectedRouteProps = {
  isAllowed: boolean;
  redirectPath: string;
  children: any;
};

type ScrollToTopProps = {
  children: ReactNode;
};

const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const scrollOptions = {
      duration: 300,
      smooth: true,
    };

    animateScroll.scrollToTop(scrollOptions);
  }, [location]);

  return <>{children}</>;
};

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/landing",
  children,
}: ProtectedRouteProps) => {
  if (!isAllowed) return <Navigate to={redirectPath} replace />;

  return children ?? <Outlet />;
};

export const Router = ({ setLocation }: any) => {
  const { userToken, userEmail } = useSelector(userSelector);
  const location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  }, [location]);

  return useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: (
        <ProtectedRoute isAllowed={!!userEmail} redirectPath="/">
          <RegisterPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/resetPassword",
      element: <ResetPasswordPage />,
    },
    {
      path: "/reset/:id",
      element: <NewPassword />
    },
    {
      path: "/main/*",
      element: (
        <ProtectedRoute isAllowed={!!userToken} redirectPath="/">
          <>
            <Layout />
            <Wrapper>
              <ScrollToTop>
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route
                    path="/cardsOperations"
                    element={<CardsOperationsPage />}
                  />
                  <Route path="/verification" element={<Verification />} />
                  <Route path="/order-new-card" element={<CardsPage />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/support" element={<SupportPage />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/markets" element={<MarketPage />} />
                </Routes>
              </ScrollToTop>
            </Wrapper>
          </>
        </ProtectedRoute>
      ),
    },
    {
      path: "/nft/*",
      element: (
        <ProtectedRoute isAllowed={!!userToken} redirectPath="/">
          <>
            <Layout />
            <Wrapper>
              <ScrollToTop>
                <Routes>
                  <Route path="/" element={<NFTPage />} />
                  <Route path="/item/:id" element={<NFTItemPage />} />
                  <Route
                    path="/item-collection/:id"
                    element={<NFTCollectionItemPage />}
                  />
                  <Route path="/catalog" element={<NFTCatalogPage />} />
                  <Route
                    path="/my-collection"
                    element={<NFTMyCollectionPage />}
                  />
                  <Route path="/create-nft" element={<CreateNFT />} />
                  <Route
                    path="/create-collection"
                    element={<CreateNFTCollectionPage />}
                  />
                  <Route path="/profile" element={<NFTProfile />} />
                  <Route path="/plans" element={<NFTPlansPage />} />
                </Routes>
              </ScrollToTop>
            </Wrapper>
          </>
        </ProtectedRoute>
      ),
    },
    { path: "*", element: <Page404 /> },
  ]);
}
