import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import { theme } from "../../../theme/default/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 24px 0;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 16px;
`;

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  externalDataContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingTop: theme.metrics.x3,
  },
  infoRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.metrics.x2,
    borderBottomWidth: theme.metrics.x025,
    borderBottomColor: theme.colors.borderGrey
  },
  greySubTextSmallSemiBold: {
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.greySubText,
    margin: 0
  },
  yellowTextSmallSemiBold: {
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.yellowMain,
    wordWrap: 'break-word',
    maxWidth: 295,
    margin: 0
  },
});