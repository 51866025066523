type RunMode = "DEVELOPMENT" | "PRODUCTION";

const RUN_MODE: RunMode = "PRODUCTION";

type RunModeDependentValue = { [k in RunMode]: any };

const AGENT_ID = 23849;
const ORIGINAL_ZEDCEX_CARD_AGENT_ID = 23849;
const IS_WHITE_LABEL = AGENT_ID === ORIGINAL_ZEDCEX_CARD_AGENT_ID;

const CONFIG_INNER = {
  RUN_MODE: RUN_MODE as RunMode,
  BASE_URL: {
    DEVELOPMENT: "https://testapp.zedcex.com/api",
    PRODUCTION: "https://app.zedcex.com/api",
  },
  ZED_BASE_URL_TEST: "https://test.zed-pay.com",
  ZED_BASE_URL_PROD: "https://zed-pay.com",
  ZED_BASE_URL: {
    DEVELOPMENT: "https://test.zed-pay.com",
    PRODUCTION: "https://zed-pay.com",
  } as RunModeDependentValue,
  ZED_ASSETS_PATH: {
    IMAGES: "/assets/images/",
    FILES_UPLOAD: "/files/upload/",
    NFT_FILES: "/nft/files/"
  },
  ZED_API_TOTAL_CURRENCY: "USD,EUR",
  USE_CODEBUD: false,
  API_DATA_CACHE_LIFETIME_HRS: 24,
  AGENT_ID,
  USDZ_CURRENCY_ID: {
    DEVELOPMENT: 38,
    PRODUCTION: 24
  },
  CURRENCY_OPTIONS: { separator: " ", decimal: "," },
  CURRENCY_OPTIONS_NO_SYMBOL: { separator: " ", decimal: ",", symbol: "" },
  PERCENT_OPTIONS: { decimal: "." },
  APP_INFO: {
    DISPLAY_VERSION: "0.3.5",
    DISPLAY_BUILD_NUMBER: "25"
  },
  INTERCOM: {
    APP_ID: "hgs7jbgw"
  },
  DATE_TIME: {
    SECONDS_IN_A_DAY: 86400
  },
  LINKS: {
    NFT_MARKETPLACE: "https://zed-pay.com/nft/",
    PRIVACY_POLICY: "https://batbank.co.id/privacy-policy/",
    TERMS_OF_USE: "https://drive.google.com/file/d/1fmPrv5V1IJZdfkn2xtk2BRc9VAZIwpox/view?usp=sharing"
  },
  IS_WHITE_LABEL,
  DEFAULT_REFERRAL_CODE: {
    DEVELOPMENT: "",
    PRODUCTION: "ZP21500135"
  }
} as const;

export const CONFIG = {
  ...CONFIG_INNER,
  BASE_URL: CONFIG_INNER.BASE_URL[RUN_MODE],
  ZED_BASE_URL: CONFIG_INNER.ZED_BASE_URL[RUN_MODE],
  USDZ_CURRENCY_ID: CONFIG_INNER.USDZ_CURRENCY_ID[RUN_MODE],
  DEFAULT_REFERRAL_CODE: CONFIG_INNER.DEFAULT_REFERRAL_CODE[RUN_MODE]
} as const;
