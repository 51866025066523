import {Action} from '@reduxjs/toolkit';
import {ApiResponse} from 'apisauce';
import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api, RequestErrorFromResponse } from '../../api';
import { takeLeading } from '../../store/sagaHelper';
import { CurrencyTable, GeneralActions } from './GeneralRedux';
import { AboutResponse, CommissionsResponse, ContactsResponse, CurrencyListResponse, CurrencyListToOpenResponse, FaqResponse, GenerateQRCodeResponse, GetBankAccountsResponse, ListCountriesResponse, LocalBanksResponse, SearchBanksResponse, SwiftCountriesResponse, WithdrawTypesResponse } from '../../types/api';
import { countriesToDataPicker } from '../../helpers/formatHelpers';
import { getAboutFromLocalStorage, getBankAccountsFromLocalStorage, getCommissionsFromLocalStorage, getContactsFromLocalStorage, getCurrencyListFromLocalStorage, getCurrencyListToOpenFromLocalStorage, getFaqFromLocalStorage, getListCountriesFromLocalStorage, getSwiftCountriesFromLocalStorage, storeAboutToLocalStorage, storeBankAccountsToLocalStorage, storeCommissionsToLocalStorage, storeContactsToLocalStorage, storeCurrencyListToLocalStorage, storeCurrencyListToOpenToLocalStorage, storeFaqToLocalStorage, storeListCountriesToLocalStorage, storeSwiftCountriesToLocalStorage } from '../../helpers/localStorage';
import { userTokenSelector } from '../user/UserSelectors';
import { cacheHelper } from '../../helpers/sagaHelpers/sagaHelpers';
import { CONFIG } from '../../config';
import moment from 'moment';
import { retryApiCall, sagaFactory, zedApiResponseDataTransformer, zedApiResponseValidator } from '../../helpers/sagaHelpers';
import { CommissionsTable } from '../../types/types';

function* listCountriesRequest(action: Action): Generator<any, void, any> {
  if (GeneralActions.listCountries.request.match(action)) {
    try {
      const cached = yield call(cacheHelper, {
        getFromLocalStorageFn: getListCountriesFromLocalStorage,
        successFn: GeneralActions.listCountries.success
      });

      if (cached)
        return;

      const listCountriesResponse: ApiResponse<ListCountriesResponse> = yield call(retryApiCall, {
        apiRequest: api.listCountriesGet,
        maxTries: 3,
        delayMs: 2e3
      });
      if (listCountriesResponse.ok && listCountriesResponse.data?.success) {
        const responseData = listCountriesResponse.data;
        const countries = countriesToDataPicker(responseData.value);
        const countriesTable: {[key: string]: string} = {};
        countries.forEach(c => (countriesTable[c.value] = c.label));

        const countriesData = {
          rawCountries: responseData.value,
          countries,
          countriesTable,
          timestamp: moment().valueOf()
        };

        yield call(storeListCountriesToLocalStorage, countriesData);

        yield put(GeneralActions.listCountries.success(countriesData));
      } else {
        throw new RequestErrorFromResponse(listCountriesResponse);
      }
    } catch (error) {
      yield put(GeneralActions.listCountries.failure(error));
    }
  }
}

function* listDocumentsRequest(action: Action) {
  if (GeneralActions.listDocuments.request.match(action)) {
    try {
      const listDocumentsResponse: ApiResponse<any> = yield call(retryApiCall, {
        apiRequest: api.listDocumentsGet,
        maxTries: 3,
        delayMs: 2e3
      });
      if (listDocumentsResponse.ok && listDocumentsResponse.data) {
        const responseData = listDocumentsResponse.data;
        yield put(GeneralActions.listDocuments.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(listDocumentsResponse);
      }
    } catch (error) {
      yield put(GeneralActions.listDocuments.failure(error));
    }
  }
}

const currencyListToOpenRequest = sagaFactory<CurrencyListToOpenResponse>({
  restActions: GeneralActions.currencyListToOpen,
  apiRequest: api.currencyListToOpenGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  cachingOptions: {
    getFromLocalStorageFn: getCurrencyListToOpenFromLocalStorage,
    storeToLocalStorageFn: storeCurrencyListToOpenToLocalStorage,
    successFn: GeneralActions.currencyListToOpen.success,
    dataKey: "currencies"
  },
  retryOptions: {
    delayMs: 500
  }
});

function* uploadFileRequest(action: Action) {
  const token: string = yield select(userTokenSelector);
   if (GeneralActions.uploadFile.request.match(action)) {
      try {
         const uploadFileResponse: ApiResponse<any> = yield call(
            api.uploadFile,
            action.payload,
            token,
         );
         if (uploadFileResponse.ok && uploadFileResponse.data) {
            const responseData = uploadFileResponse.data;
            yield put(GeneralActions.uploadFile.success(responseData));
         } else {
            throw new RequestErrorFromResponse(uploadFileResponse);
         }
      } catch (error) {
         yield put(GeneralActions.uploadFile.failure(error));
      }
   }
  }
  
function* generateQRCodeRequest(action: Action) {
  if (GeneralActions.generateQRCode.request.match(action)) {
    try {            
      const generateQRCodeResponse: ApiResponse<GenerateQRCodeResponse> = yield call(retryApiCall, {
        apiRequest: api.generateQRCodeGet,
        args: [action.payload]
      });
      if (generateQRCodeResponse.ok && generateQRCodeResponse.data?.success) {
        const responseData = generateQRCodeResponse.data;

        yield put(GeneralActions.addQRCodeToTable({
          key: action.payload.text,
          url: `${CONFIG.ZED_BASE_URL}/${responseData.value}`
        }));

        yield put(GeneralActions.generateQRCode.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(generateQRCodeResponse);
      }
    } catch (error) {
      yield put(GeneralActions.generateQRCode.failure(error));
    }
  }
}

function* faqRequest(action: Action): Generator<any, void, any> {
  if (GeneralActions.faq.request.match(action)) {
    try {
      const cached = yield call(cacheHelper, {
        getFromLocalStorageFn: getFaqFromLocalStorage,
        successFn: GeneralActions.faq.success
      });

      if (cached)
        return;
      
      const faqResponse: ApiResponse<FaqResponse> = yield call(
        api.getWhitelabelData,
        action.payload
      );
      if (faqResponse.ok && faqResponse.data?.success) {
        const responseData = faqResponse.data;

        const faqData = {
          value: responseData.value[0],
          timestamp: moment().valueOf()
        };

        yield call(storeFaqToLocalStorage, faqData);

        yield put(GeneralActions.faq.success(faqData));
      } else {
        throw new RequestErrorFromResponse(faqResponse);
      }
    } catch (error) {
      yield put(GeneralActions.faq.failure(error));
    }
  }
}

function* aboutRequest(action: Action): Generator<any, void, any> {
  if (GeneralActions.about.request.match(action)) {
    try {
      const cached = yield call(cacheHelper, {
        getFromLocalStorageFn: getAboutFromLocalStorage,
        successFn: GeneralActions.about.success
      });

      if (cached)
        return;
      
      const aboutResponse: ApiResponse<AboutResponse> = yield call(
        api.getWhitelabelData,
        action.payload
      );
      if (aboutResponse.ok && aboutResponse.data?.success) {
        const responseData = aboutResponse.data;

        const aboutData = {
          value: responseData.value[0],
          timestamp: moment().valueOf()
        };

        yield call(storeAboutToLocalStorage, aboutData);

        yield put(GeneralActions.about.success(aboutData));
      } else {
        throw new RequestErrorFromResponse(aboutResponse);
      }
    } catch (error) {
      yield put(GeneralActions.about.failure(error));
    }
  }
}

function* contactsRequest(action: Action): Generator<any, void, any> {
  if (GeneralActions.contacts.request.match(action)) {
    try {
      const cached = yield call(cacheHelper, {
        getFromLocalStorageFn: getContactsFromLocalStorage,
        successFn: GeneralActions.contacts.success
      });

      if (cached)
        return;
      
      const contactsResponse: ApiResponse<ContactsResponse> = yield call(
        api.getWhitelabelData,
        action.payload
      );
      if (contactsResponse.ok && contactsResponse.data?.success) {
        const responseData = contactsResponse.data;

        const contactsData = {
          value: responseData.value[0],
          timestamp: moment().valueOf()
        };

        yield call(storeContactsToLocalStorage, contactsData);

        yield put(GeneralActions.contacts.success(contactsData));
      } else {
        throw new RequestErrorFromResponse(contactsResponse);
      }
    } catch (error) {
      yield put(GeneralActions.contacts.failure(error));
    }
  }
}

function* getBankAccountsRequest(action: Action): Generator<any, void, any> {
  if (GeneralActions.getBankAccounts.request.match(action)) {
    try {
      const cached = yield call(cacheHelper, {
        getFromLocalStorageFn: getBankAccountsFromLocalStorage,
        successFn: GeneralActions.getBankAccounts.success
      }, 4);

      if (cached)
        return;
      
      const getBankAccountsResponse: ApiResponse<GetBankAccountsResponse> = yield call(
        api.getBankAccountsGet
      );
      if (getBankAccountsResponse.ok && getBankAccountsResponse.data?.success) {
        const responseData = getBankAccountsResponse.data;

        const data = {
          accounts: responseData.value,
          timestamp: moment().valueOf()
        };

        yield call(storeBankAccountsToLocalStorage, data);

        yield put(GeneralActions.getBankAccounts.success(data));
      } else {
        throw new RequestErrorFromResponse(getBankAccountsResponse);
      }
    } catch (error) {
      yield put(GeneralActions.getBankAccounts.failure(error));
    }
  }
}

function* searchBanksRequest(action: Action) {
  if (GeneralActions.searchBanks.request.match(action)) {
    try {      
      const searchBanksResponse: ApiResponse<SearchBanksResponse> = yield call(
        api.searchBanksGet,
        action.payload
      );
      if (searchBanksResponse.ok && searchBanksResponse.data?.success) {
        const responseData = searchBanksResponse.data;
        yield put(GeneralActions.searchBanks.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(searchBanksResponse);
      }
    } catch (error) {
      yield put(GeneralActions.searchBanks.failure(error));
    }
  }
}

function* localBanksRequest(action: Action) {
  if (GeneralActions.localBanks.request.match(action)) {
    try {      
      const localBanksResponse: ApiResponse<LocalBanksResponse> = yield call(
        api.localBanksGet,
        action.payload
      );
      if (localBanksResponse.ok && localBanksResponse.data?.success) {
        const responseData = localBanksResponse.data;
        yield put(GeneralActions.localBanks.success(responseData.value as any));
      } else {
        throw new RequestErrorFromResponse(localBanksResponse);
      }
    } catch (error) {
      yield put(GeneralActions.localBanks.failure(error));
    }
  }
}

const withdrawTypesRequest = sagaFactory<WithdrawTypesResponse>({
  restActions: GeneralActions.withdrawTypes,
  apiRequest: api.withdrawTypesGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: (data) => {
    let newArray = data.value.map((item:any) => { return {label:item.title,value:item.title}})
    return newArray
  }
});

function* swiftCountriesRequest(action: Action): Generator<any, void, any> {
  if (GeneralActions.swiftCountries.request.match(action)) {
    try {
      const cached = yield call(cacheHelper, {
        getFromLocalStorageFn: getSwiftCountriesFromLocalStorage,
        successFn: GeneralActions.swiftCountries.success
      }, 12);

      if (cached)
        return;
      
      const swiftCountriesResponse: ApiResponse<SwiftCountriesResponse> = yield call(
        api.swiftCountriesGet
      );
      if (swiftCountriesResponse.ok && swiftCountriesResponse.data?.success) {
        const responseData = swiftCountriesResponse.data;

        const data = {
          countries: responseData.value,
          timestamp: moment().valueOf()
        };

        yield call(storeSwiftCountriesToLocalStorage, data);

        yield put(GeneralActions.swiftCountries.success(data));
      } else {
        throw new RequestErrorFromResponse(swiftCountriesResponse);
      }
    } catch (error) {
      yield put(GeneralActions.swiftCountries.failure(error));
    }
  }
}

const commissionsRequest = sagaFactory<CommissionsResponse, CommissionsTable>({
  restActions: GeneralActions.commissions,
  apiRequest: api.commissionsGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: (data) => {
    const table: CommissionsTable = {};
    data.value.forEach((c) => void(table[c.transaction_type] = c));
    return table;
  },
  cachingOptions: {
    getFromLocalStorageFn: getCommissionsFromLocalStorage,
    storeToLocalStorageFn: storeCommissionsToLocalStorage,
    successFn: GeneralActions.commissions.success,
    dataKey: "commissionsTable",
    cacheLifetime: 12
  }
});

const currencyListRequest = sagaFactory<CurrencyListResponse>({
  restActions: GeneralActions.currencyList,
  apiRequest: api.currencyListGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: (data) => {
    const table: CurrencyTable = {};

    for (const c of data.value)
      table[c.id] = c;

    return table;
  },
  cachingOptions: {
    getFromLocalStorageFn: getCurrencyListFromLocalStorage,
    storeToLocalStorageFn: storeCurrencyListToLocalStorage,
    successFn: GeneralActions.currencyList.success,
    dataKey: "currencies"
  },
  retryOptions: {
    delayMs: 3e3,
    maxTries: 3
  }
});

export function* GeneralSaga() {
  yield* [
    takeLatest(GeneralActions.listCountries.request.type, listCountriesRequest),
    takeLeading(
      GeneralActions.listDocuments.request.type,
      listDocumentsRequest,
    ),
    takeLatest(GeneralActions.currencyListToOpen.request.type, currencyListToOpenRequest),
    takeLatest(GeneralActions.uploadFile.request.type, uploadFileRequest),
    takeLatest(GeneralActions.generateQRCode.request.type, generateQRCodeRequest),
    takeLeading(GeneralActions.faq.request.type, faqRequest),
    takeLeading(GeneralActions.about.request.type, aboutRequest),
    takeLeading(GeneralActions.contacts.request.type, contactsRequest),
    takeLeading(GeneralActions.getBankAccounts.request.type, getBankAccountsRequest),
    takeLatest(GeneralActions.searchBanks.request.type, searchBanksRequest),
    takeLatest(GeneralActions.localBanks.request.type, localBanksRequest),
    takeLeading(GeneralActions.swiftCountries.request.type, swiftCountriesRequest),
    takeLeading(GeneralActions.commissions.request.type, commissionsRequest),
    takeLeading(GeneralActions.currencyList.request.type, currencyListRequest),
    takeLeading(GeneralActions.withdrawTypes.request.type, withdrawTypesRequest)
  ];
}