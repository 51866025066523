import {
  addAllRestReducers,
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "../../store/restHelper";
import {
  ActivateRestActions,
  AllowedCountriesRestActions,
  CardCommissionsRestActions,
  CardFeaturesRestActions,
  CardToCardRestActions,
  CardTransactionsRestActions,
  CreateVirtualCardRestActions,
  CreateVisaCardRestActions,
  GetCVVRestActions,
  GetMyCardsRestActions,
  GetTransactionsRestActions,
  IssueVirtualCardRestActions,
  LoadPhysicalCardRestActions,
  LoadVirtualCardRestActions,
  NewHolderRestActions,
  SubmitKycRestActions,
  UnloadPhysicalCardRestActions,
  UnloadVirtualCardRestActions,
} from "./CardsActions";
import {
  CreateVirtualCardRequest,
  CreateVirtualCardResponse,
  CreateVisaCardRequest,
  CreateVisaCardResponse,
  GetTransactionsRequest,
  NewsResponse,
} from "../../types/api";
import { NodeRestStateType } from "../../store/restHelper.d";
import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import {
  CardCommissions,
  CardHolderInfo,
  CountryCardAvailability,
  GenderBasic,
  GetTransactionsItem,
  KeyValueObj,
  KycInfo,
  WithTimestamp,
  ZedpayCard,
  ZedpayCardFullInfo,
  ZedpayCardNetwork,
  ZedpayCardTransaction,
} from "../../types/types";
import fp from "lodash/fp";

type GetTransactionsResponse = GetTransactionsItem[];

type AllowedCountriesResponse = WithTimestamp<{
  countries: CountryCardAvailability[];
}>;

type GetMyCardsResponse = ZedpayCardFullInfo[];

type GetMyCardsRequest = {
  customerId: number;
};

type CardFeaturesResponse = WithTimestamp<{
  cards: {
    visa_physical: KeyValueObj[];
    visa_virtual: KeyValueObj[];
    master_virtual: KeyValueObj[];
    visa_physical_card: string;
    visa_virtual_card: string;
    master_virtual_card: string;
  };
}>;

type GetCVVResponse = string;

type LoadVirtualCardResponse = ZedpayCardFullInfo;

type LoadPhysicalCardResponse = ZedpayCardFullInfo;

type UnloadVirtualCardResponse = ZedpayCardFullInfo;

type UnloadPhysicalCardResponse = ZedpayCardFullInfo;

type CardCommissionsResponse = WithTimestamp<{
  commissions: CardCommissions;
}>;

type CardTransactionsResponse = ZedpayCardTransaction[];

type CardToCardResponse = ZedpayCardFullInfo;

type GetCVVPayload = {
  id: string | number;
  virtual: boolean;
};

type LoadVirtualCardPayload = {
  id: number;
  amount: number;
  otp_code: string;
};

type LoadPhysicalCardPayload = {
  id: number;
  amount: number;
  otp_code: string;
};

type UnloadVirtualCardPayload = {
  id: number;
  amount: number;
  otp_code: string;
};

type UnloadPhysicalCardPayload = {
  id: number;
  amount: number;
  otp_code: string;
};

type CardTransactionsPayload = {
  id: string | number;
  month?: string;
  year?: string;
};

type CardToCardPayload = {
  from_id: number | string;
  to_card_number: string;
  amount: number;
  otp_code: string;
};

type NewHolderPayload = {
  info: CardHolderInfo;
  gender: GenderBasic;
  birth_date: string;
  virtual?: boolean;
};

type SubmitKycPayload = KycInfo;

type ActivatePayload = {
  id: number;
  otp_code: string;
  successCallback?: () => void;
};

type IssueVirtualCardResponse = ZedpayCardFullInfo;

export type TransactionsByAccount = { [key: string]: GetTransactionsItem[] };

export type TransactionsByCard = { [key: string]: ZedpayCardTransaction[] };

export type CardsLabelsTable = { [key: string]: string };

type AddCardLabelPayload = {
  id: string;
  label: string;
  callback?: (cardsLabels: CardsLabelsTable) => void;
};

type SetAccountTransactionsPayload = {
  account_id: number;
  transactions: GetTransactionsItem[];
};

type SetCardTransactionsPayload = {
  card_id: number | string;
  transactions: ZedpayCardTransaction[];
};

type IssueVirtualCardPayload = {
  customer_id: number;
  surname: string;
  amount: number;
  name: string;
  address_line1: string;
  city: string;
  state: string;
  country: number;
  zip: string;
  phone: string;
  email: string;
  network: ZedpayCardNetwork;
  referral_code: string;
};

const getMyCardsRestActions = createRestActions<
  GetMyCardsResponse,
  GetMyCardsRequest
>(GetMyCardsRestActions);

const createVirtualCardRestActions = createRestActions<
  CreateVirtualCardResponse,
  CreateVirtualCardRequest
>(CreateVirtualCardRestActions);

const createVisaCardRestActions = createRestActions<
  CreateVisaCardResponse,
  CreateVisaCardRequest
>(CreateVisaCardRestActions);

const getTransactionsRestActions = createRestActions<
  GetTransactionsResponse,
  GetTransactionsRequest
>(GetTransactionsRestActions);

const allowedCountriesRestActions = createRestActions<
  AllowedCountriesResponse
>(AllowedCountriesRestActions);

const cardFeaturesRestActions = createRestActions<
  CardFeaturesResponse
>(CardFeaturesRestActions);

const issueVirtualCardRestActions = createRestActions<
  IssueVirtualCardResponse,
  IssueVirtualCardPayload
>(IssueVirtualCardRestActions);

const getCVVRestActions = createRestActions<
  GetCVVResponse,
  GetCVVPayload
>(GetCVVRestActions);

const loadVirtualCardRestActions = createRestActions<
  LoadVirtualCardResponse,
  LoadVirtualCardPayload
>(LoadVirtualCardRestActions);

const loadPhysicalCardRestActions = createRestActions<
  LoadPhysicalCardResponse,
  LoadPhysicalCardPayload
>(LoadPhysicalCardRestActions);

const cardTransactionsRestActions = createRestActions<
  CardTransactionsResponse,
  CardTransactionsPayload
>(CardTransactionsRestActions);

const cardToCardRestActions = createRestActions<
  CardToCardResponse,
  CardToCardPayload
>(CardToCardRestActions);

const newHolderRestActions = createRestActions<
  {status:boolean} | undefined,
  NewHolderPayload
>(NewHolderRestActions);

const submitKycRestActions = createRestActions<
  void,
  SubmitKycPayload
>(SubmitKycRestActions);

const activateRestActions = createRestActions<
  void,
  ActivatePayload
>(ActivateRestActions);

const unloadVirtualCardRestActions = createRestActions<
  UnloadVirtualCardResponse,
  UnloadVirtualCardPayload
>(UnloadVirtualCardRestActions);

const unloadPhysicalCardRestActions = createRestActions<
  UnloadPhysicalCardResponse,
  UnloadPhysicalCardPayload
>(UnloadPhysicalCardRestActions);

const cardCommissionsRestActions = createRestActions<
  CardCommissionsResponse
>(CardCommissionsRestActions);

const CardsRestActions = {
  getMyCards: getMyCardsRestActions,
  createVirtualCard: createVirtualCardRestActions,
  createVisaCard: createVisaCardRestActions,
  getTransactions: getTransactionsRestActions,
  allowedCountries: allowedCountriesRestActions,
  cardFeatures: cardFeaturesRestActions,
  issueVirtualCard: issueVirtualCardRestActions,
  getCVV: getCVVRestActions,
  loadVirtualCard: loadVirtualCardRestActions,
  loadPhysicalCard: loadPhysicalCardRestActions,
  cardTransactions: cardTransactionsRestActions,
  cardToCard: cardToCardRestActions,
  newHolder: newHolderRestActions,
  submitKyc: submitKycRestActions,
  activate: activateRestActions,
  unloadVirtualCard: unloadVirtualCardRestActions,
  unloadPhysicalCard: unloadPhysicalCardRestActions,
  cardCommissions: cardCommissionsRestActions,
}

const CardsActions = {
  ...CardsRestActions,
  setAccountTransactions: createAction<SetAccountTransactionsPayload>(
    "cards/setAccountTransactions"
  ),
  clearAccountTransactions: createAction("cards/clearAccountTransactions"),
  setCardTransactions: createAction<SetCardTransactionsPayload>(
    "cards/setCardTransactions"
  ),
  clearCardTransactions: createAction("cards/clearCardTransactions"),
  setCardsLabelsTable: createAction<CardsLabelsTable>(
    "cards/setCardsLabelsTable"
  ),
  addCardLabel: createAction<AddCardLabelPayload>("cards/addCardLabel"),
  clearCardsLabelsTable: createAction("cards/clearCardsLabelsTable"),
  setCardsOperationsPageSelectedCard: createAction<ZedpayCardFullInfo | null>("cards/setCardsOperationsPageSelectedCard"),
  setCardsOperationsPageShowDetails: createAction<boolean>('cards/setCardsOperationsPageShowDetails'),
  setCardToActivate: createAction<ZedpayCardFullInfo | null>('cards/setCardToActivate')
};

type CardsRestNodes = keyof typeof CardsRestActions;

type CardsStore = {
  transactionsByAccount: TransactionsByAccount;
  cardsLabels: CardsLabelsTable;
  transactionsByCard: TransactionsByCard;
  cardsOperationsPageSelectedCard: ZedpayCardFullInfo | null;
  cardsOperationsPageShowDetails: boolean;
  cardToActivate: ZedpayCardFullInfo | null;
};

const initialCardsStore: CardsStore = {
  transactionsByAccount: {},
  cardsLabels: {},
  transactionsByCard: {},
  cardsOperationsPageSelectedCard: null,
  cardsOperationsPageShowDetails: false,
  cardToActivate: null
};

const initialRestState = {
  getMyCards: getDefaultRestState<GetMyCardsResponse>(),
  createVirtualCard: getDefaultRestState<CreateVirtualCardResponse>(),
  createVisaCard: getDefaultRestState<CreateVisaCardResponse>(),
  getTransactions: getDefaultRestState<GetTransactionsResponse>(),
  allowedCountries: getDefaultRestState<AllowedCountriesResponse>(),
  cardFeatures: getDefaultRestState<CardFeaturesResponse>(),
  issueVirtualCard: getDefaultRestState<IssueVirtualCardResponse>(),
  getCVV: getDefaultRestState<GetCVVResponse>(),
  loadVirtualCard: getDefaultRestState<LoadVirtualCardResponse>(),
  loadPhysicalCard: getDefaultRestState<LoadPhysicalCardResponse>(),
  cardTransactions: getDefaultRestState<CardTransactionsResponse>(),
  cardToCard: getDefaultRestState<CardToCardResponse>(),
  newHolder: getDefaultRestState(),
  submitKyc: getDefaultRestState(),
  activate: getDefaultRestState(),
  unloadVirtualCard: getDefaultRestState<UnloadVirtualCardResponse>(),
  unloadPhysicalCard: getDefaultRestState<UnloadPhysicalCardResponse>(),
  cardCommissions: getDefaultRestState<CardCommissionsResponse>()
};

type CardsState = NodeRestStateType<
  CardsRestNodes,
  CardsStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<CardsState>;

const cardsReducer = createReducer(
  { ...initialCardsStore, ...initialRestState },
  (builder) =>
    (
      fp.flow(addAllRestReducers<typeof CardsRestActions>(CardsRestActions))(builder) as Builder
    )
      .addCase(CardsActions.setAccountTransactions, (state, action) => {
        state.transactionsByAccount[action.payload.account_id] =
          action.payload.transactions;
      })
      .addCase(CardsActions.setCardTransactions, (state, action) => {
        state.transactionsByCard[action.payload.card_id] =
          action.payload.transactions;
      })
      .addCase(CardsActions.clearAccountTransactions, (state) => {
        state.transactionsByAccount = {};
      })
      .addCase(CardsActions.clearCardTransactions, (state) => {
        state.transactionsByCard = {};
      })
      .addCase(CardsActions.setCardsLabelsTable, (state, action) => {
        state.cardsLabels = action.payload;
      })
      .addCase(CardsActions.clearCardsLabelsTable, (state) => {
        state.cardsLabels = {};
      })
      .addCase(CardsActions.addCardLabel, (state, action) => {
        state.cardsLabels[action.payload.id] = action.payload.label;

        if (action.payload.callback) action.payload.callback(state.cardsLabels);
      })
      .addCase(CardsActions.setCardsOperationsPageSelectedCard, (state, action) => {
        state.cardsOperationsPageSelectedCard = action.payload;
      })
      .addCase(CardsActions.setCardsOperationsPageShowDetails, (state, action) => {
        state.cardsOperationsPageShowDetails = action.payload;
      })
      .addCase(CardsActions.setCardToActivate, (state, action) => {
        state.cardToActivate = action.payload;
      })
);

export { cardsReducer, CardsActions };
