import _ from 'lodash';
import {
  takeLeading,
} from 'redux-saga/effects';
import {api} from '../../api';
import { sagaFactory, zedApiResponseDataTransformer, zedApiResponseValidator } from '../../helpers/sagaHelpers';
import { EscrowActions } from './EscrowRedux';
import * as T from '../../types/api';

const changeRequest = sagaFactory<T.EscrowChangeResponse>({
  restActions: EscrowActions.change,
  apiRequest: api.escrowChange,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const deleteRequest = sagaFactory<T.EscrowDeleteResponse>({
  restActions: EscrowActions.delete,
  apiRequest: api.escrowDelete,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const editRequest = sagaFactory<T.EscrowEditResponse>({
  restActions: EscrowActions.edit,
  apiRequest: api.escrowEdit,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const listRequest = sagaFactory<T.EscrowListResponse>({
  restActions: EscrowActions.list,
  apiRequest: api.escrowList,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

const newRequest = sagaFactory<T.EscrowNewResponse>({
  restActions: EscrowActions.new,
  apiRequest: api.escrowNew,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  toastOptions:{
    showSuccess: true,
    showFailure: true
  }
});

export function* EscrowSaga() {
  yield* [
    takeLeading(EscrowActions.change.request.type,changeRequest),
    takeLeading(EscrowActions.delete.request.type,deleteRequest),
    takeLeading(EscrowActions.edit.request.type,editRequest),
    takeLeading(EscrowActions.list.request.type,listRequest),
    takeLeading(EscrowActions.new.request.type,newRequest),
  ];
}