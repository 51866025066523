import { Action } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { api, RequestErrorFromResponse } from "../../api";
import { CardsActions, TransactionsByCard } from "./CardsRedux";
import { ApiResponse } from "apisauce";
import { cacheHelper } from "../../helpers/sagaHelpers/sagaHelpers";
import {
  ActivateResponse,
  AllowedCountriesResponse,
  CardCommissionsResponse,
  CardFeaturesResponse,
  CardToCardResponse,
  CardTransactionsResponse,
  CreateVirtualCardResponse,
  CreateVisaCardResponse,
  ErrorResponse,
  GetCVVResponse,
  GetMyCardsResponse,
  GetTransactionsResponse,
  IssueVirtualCardResponse,
  LoadPhysicalCardResponse,
  LoadVirtualCardRespone,
  NewHolderResponse,
  SubmitKycResponse,
  UnloadPhysicalCardResponse,
  UnloadVirtualCardResponse,
} from "../../types/api";
import {
  getCardCommissionsFromLocalStorage,
  getCardFeaturesFromLocalStorage,
  getCardsAllowedCountriesFromLocalStorage,
  storeCardCommissionsToLocalStorage,
  storeCardFeaturesToLocalStorage,
  storeCardsAllowedCountriesToLocalStorage,
} from "../../helpers/localStorage";
import { showToast } from "../../helpers/alertService";
import { cardsOperationsPageSelectedCardSelector, myCardsSelector, transactionsByCardSelector } from "./CardsSelectors";
// import { pop } from '../../helpers/navigationHelpers';
import { ZedpayCardFullInfo } from "../../types/types";
import { retryApiCall, sagaFactory, zedApiResponseDataTransformer, zedApiResponseValidator } from "../../helpers/sagaHelpers";
import moment from "moment";
import i18next from "i18next";

function* getMyCardsRequest(action: Action) {
  if (CardsActions.getMyCards.request.match(action)) {
    try {

      const [getMyFCFCardsResponse, getMyAlchemaCardsResponse]: [
        ApiResponse<GetMyCardsResponse>,
        ApiResponse<GetMyCardsResponse>
      ] = yield all([
        call(retryApiCall, {
          apiRequest: api.getMyFCFCards,
          args: [action.payload],
        }),
        call(retryApiCall, {
          apiRequest: api.getMyAlchemaCards,
          args: [{ customer_id:action.payload.customerId, fcfview: true }],
        }),
      ]);

      if (zedApiResponseValidator(getMyFCFCardsResponse) && zedApiResponseValidator(getMyAlchemaCardsResponse)) {
        const responseData = [...getMyFCFCardsResponse.data?.value!,...getMyAlchemaCardsResponse.data?.value!];

        // If no transactions history for first card then request it
        if (responseData.length > 0) {
          const transactionsByCard: TransactionsByCard = yield select(
            transactionsByCardSelector
          );

          if (!transactionsByCard[responseData[0].id])
            yield put(
              CardsActions.cardTransactions.request({
                id: responseData[0].id,
              })
            );
        }

        yield put(CardsActions.getMyCards.success(responseData));
      } else {
        throw new RequestErrorFromResponse(getMyAlchemaCardsResponse);
      }
    } catch (error) {
      yield put(CardsActions.getMyCards.failure(error));
    }
  }
}

function* createVirtualCardRequest(action: Action) {
  if (CardsActions.createVirtualCard.request.match(action)) {
    try {
      const createVirtualCardResponse: ApiResponse<CreateVirtualCardResponse> =
        yield call(api.createVirtualCard, action.payload);
      if (
        createVirtualCardResponse.ok &&
        createVirtualCardResponse.data?.success
      ) {
        const responseData = createVirtualCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(CardsActions.createVirtualCard.success(responseData));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
            createVirtualCardResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });

        throw new RequestErrorFromResponse(createVirtualCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.createVirtualCard.failure(error));
    }
  }
}

function* issueVirtualCardRequest(action: Action) {
  if (CardsActions.issueVirtualCard.request.match(action)) {
    try {
      const issueVirtualCardResponse: ApiResponse<IssueVirtualCardResponse> =
        yield call(api.issueVirtualCardPost, action.payload);
      if (
        issueVirtualCardResponse.ok &&
        issueVirtualCardResponse.data?.success
      ) {
        const responseData = issueVirtualCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(CardsActions.issueVirtualCard.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
            issueVirtualCardResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });

        throw new RequestErrorFromResponse(issueVirtualCardResponse);
      }
    } catch (error:any) {
      yield put(CardsActions.issueVirtualCard.failure(error));
    }
  }
}

function* createVisaCardRequest(action: Action) {
  if (CardsActions.createVisaCard.request.match(action)) {
    try {
      const createVisaCardResponse: ApiResponse<
        CreateVisaCardResponse & ErrorResponse
      > = yield call(api.createVisaCard, action.payload);
      if (createVisaCardResponse.ok && createVisaCardResponse.data) {
        const responseData = createVisaCardResponse.data;
        yield put(CardsActions.createVisaCard.success(responseData));
      } else {
        throw new RequestErrorFromResponse(createVisaCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.createVisaCard.failure(error));
    }
  }
}

function* getTransactionsRequest(action: Action) {
  if (CardsActions.getTransactions.request.match(action)) {
    try {
      const getTransactionsResponse: ApiResponse<GetTransactionsResponse> =
        yield call(api.getTransactions, action.payload);
      if (getTransactionsResponse.ok && getTransactionsResponse.data?.success) {
        const responseData = getTransactionsResponse.data;

        if (action.payload.account_id !== undefined) {
          yield put(
            CardsActions.setAccountTransactions({
              account_id: action.payload.account_id,
              transactions: responseData.value,
            })
          );

          yield put(CardsActions.getTransactions.finish());
          return;
        }

        yield put(CardsActions.getTransactions.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(getTransactionsResponse);
      }
    } catch (error) {
      yield put(CardsActions.getTransactions.failure(error));
    }
  }
}

function* allowedCountriesRequest(action: Action): Generator<any, void, any> {
  if (CardsActions.allowedCountries.request.match(action)) {
    try {
      const cached = yield call(
        cacheHelper,
        {
          getFromLocalStorageFn: getCardsAllowedCountriesFromLocalStorage,
          successFn: CardsActions.allowedCountries.success,
        },
        8
      );

      if (cached) return;

      const allowedCountriesResponse: ApiResponse<AllowedCountriesResponse> = yield call(retryApiCall, {
        apiRequest: api.allowedCountriesGet,
        delayMs: 2e3
      });
      if (
        allowedCountriesResponse.ok &&
        allowedCountriesResponse.data?.success
      ) {
        const responseData = allowedCountriesResponse.data;

        const countriesData = {
          countries: responseData.value,
          timestamp: moment().valueOf(),
        };

        yield call(storeCardsAllowedCountriesToLocalStorage, countriesData);

        yield put(CardsActions.allowedCountries.success(countriesData));
      } else {
        throw new RequestErrorFromResponse(allowedCountriesResponse);
      }
    } catch (error) {
      yield put(CardsActions.allowedCountries.failure(error));
    }
  }
}

function* cardFeaturesRequest(action: Action): Generator<any, void, any> {
  if (CardsActions.cardFeatures.request.match(action)) {
    try {
      const cached = yield call(
        cacheHelper,
        {
          getFromLocalStorageFn: getCardFeaturesFromLocalStorage,
          successFn: CardsActions.cardFeatures.success,
        },
        12
      );

      if (cached) return;

      const cardFeaturesResponse: ApiResponse<CardFeaturesResponse> = yield call(retryApiCall, {
        apiRequest: api.cardFeaturesGet,
        delayMs: 4e3
      });
      if (cardFeaturesResponse.ok && cardFeaturesResponse.data?.success) {
        const responseData = cardFeaturesResponse.data;

        const featuresData = {
          cards: responseData.value,
          timestamp: moment().valueOf(),
        };

        yield call(storeCardFeaturesToLocalStorage, featuresData);

        yield put(CardsActions.cardFeatures.success(featuresData));
      } else {
        throw new RequestErrorFromResponse(cardFeaturesResponse);
      }
    } catch (error) {
      yield put(CardsActions.cardFeatures.failure(error));
    }
  }
}

function* getCVVRequest(action: Action) {
  if (CardsActions.getCVV.request.match(action)) {
    try {
      const getCVVResponse: ApiResponse<GetCVVResponse> = yield call(retryApiCall, {
        apiRequest: action.payload.virtual?api.getAlchemaCVVGet:api.getCVVGet,
        args: [action.payload]
      });
      if (getCVVResponse.ok && getCVVResponse.data?.success) {
        const responseData = getCVVResponse.data;

        const cards: ReturnType<typeof myCardsSelector> = yield select(
          myCardsSelector
        );
        if (cards?.data) {
          const cardsCopy = JSON.parse(JSON.stringify(cards));
          for (const card of cardsCopy.data)
            if (card.id === action.payload.id) {
              card.card_cvv = responseData.value;
              break;
            }

          yield put(CardsActions.getMyCards.success(cardsCopy.data));
        }

        const cardsOperationsPageSelectedCard: ZedpayCardFullInfo | null = yield select(cardsOperationsPageSelectedCardSelector);
        if (cardsOperationsPageSelectedCard) {
          yield put(CardsActions.setCardsOperationsPageSelectedCard({...cardsOperationsPageSelectedCard, card_cvv: responseData.value}));
        }

        yield put(CardsActions.getCVV.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(getCVVResponse);
      }
    } catch (error) {
      yield put(CardsActions.getCVV.failure(error));
    }
  }
}

function* loadVirtualCardRequest(action: Action) {
  if (CardsActions.loadVirtualCard.request.match(action)) {
    try {
      const loadVirtualCardResponse: ApiResponse<LoadVirtualCardRespone> =
        yield call(api.loadVirtualCardPost, action.payload);
      if (loadVirtualCardResponse.ok && loadVirtualCardResponse.data?.success) {
        const responseData = loadVirtualCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        // yield call(pop);

        yield put(CardsActions.loadVirtualCard.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: loadVirtualCardResponse?.data?.message ?? "Error",
          type: "error",
        });
        throw new RequestErrorFromResponse(loadVirtualCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.loadVirtualCard.failure(error));
    }
  }
}

function* loadPhysicalCardRequest(action: Action) {
  if (CardsActions.loadPhysicalCard.request.match(action)) {
    try {
      const loadPhysicalCardResponse: ApiResponse<LoadPhysicalCardResponse> =
        yield call(api.loadPhysicalCardPost, action.payload);
      if (loadPhysicalCardResponse.ok && loadPhysicalCardResponse.data) {
        const responseData = loadPhysicalCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        // yield call(pop);

        yield put(CardsActions.loadPhysicalCard.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: loadPhysicalCardResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(loadPhysicalCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.loadPhysicalCard.failure(error));
    }
  }
}

function* cardTransactionsRequest(action: Action) {
  if (CardsActions.cardTransactions.request.match(action)) {
    try {
      const cardTransactionsResponse: ApiResponse<CardTransactionsResponse> =
        yield call(api.cardTransactionsGet, action.payload);
      if (
        cardTransactionsResponse.ok &&
        cardTransactionsResponse.data?.success
      ) {
        const responseData = cardTransactionsResponse.data;

        yield put(
          CardsActions.setCardTransactions({
            card_id: action.payload.id,
            transactions: responseData.value.reverse(),
          })
        );

        yield put(CardsActions.cardTransactions.success(responseData.value));
      } else {
        throw new RequestErrorFromResponse(cardTransactionsResponse);
      }
    } catch (error) {
      yield put(CardsActions.cardTransactions.failure(error));
    }
  }
}

function* cardToCardRequest(action: Action) {
  if (CardsActions.cardToCard.request.match(action)) {
    try {
      const cardToCardResponse: ApiResponse<CardToCardResponse> = yield call(
        api.cardToCardPost,
        action.payload
      );
      if (cardToCardResponse.ok && cardToCardResponse.data?.success) {
        const responseData = cardToCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        // yield call(pop);

        yield put(CardsActions.cardToCard.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: cardToCardResponse?.data?.message ?? "Error",
          type: "error",
        });
        throw new RequestErrorFromResponse(cardToCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.cardToCard.failure(error));
    }
  }
}

function* newHolderRequest(action: Action) {
  if (CardsActions.newHolder.request.match(action)) {
    try {
      const newHolderResponse: ApiResponse<NewHolderResponse> = yield call(
        api.newHolderPost,
        action.payload
      );
      if (newHolderResponse.ok && newHolderResponse.data) {
        yield put(CardsActions.newHolder.success({status:true}));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
          newHolderResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });
        throw new RequestErrorFromResponse(newHolderResponse);
      }
    } catch (error) {
      yield put(CardsActions.newHolder.failure(error));
    }
  }
}

function* submitKycRequest(action: Action) {
  if (CardsActions.submitKyc.request.match(action)) {
    try {
      const submitKycResponse: ApiResponse<SubmitKycResponse> = yield call(
        api.submitKycPost,
        action.payload
      );
      if (submitKycResponse.ok && submitKycResponse.data) {
        yield put(CardsActions.submitKyc.success());
      } else {
        throw new RequestErrorFromResponse(submitKycResponse);
      }
    } catch (error) {
      yield put(CardsActions.submitKyc.failure(error));
    }
  }
}

function* activateRequest(action: Action) {
  if (CardsActions.activate.request.match(action)) {
    try {
      const activateResponse: ApiResponse<ActivateResponse> = yield call(
        api.activatePost,
        action.payload
      );
      if (activateResponse.ok && activateResponse.data?.success) {
        yield call(showToast, {
          title: i18next.t("success"),
          info: activateResponse.data.message,
          type: "success",
        });

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield put(CardsActions.activate.success());
      } else {
        throw new RequestErrorFromResponse(activateResponse);
      }
    } catch (error) {
      yield put(CardsActions.activate.failure(error));
    }
  }
}

function* unloadVirtualCardRequest(action: Action) {
  if (CardsActions.unloadVirtualCard.request.match(action)) {
    try {      
      const unloadVirtualCardResponse: ApiResponse<UnloadVirtualCardResponse> = yield call(
        api.unloadVirtualCardPost,
        action.payload
      );
      if (unloadVirtualCardResponse.ok && unloadVirtualCardResponse.data?.success) {
        const responseData = unloadVirtualCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(CardsActions.unloadVirtualCard.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: unloadVirtualCardResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(unloadVirtualCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.unloadVirtualCard.failure(error));
    }
  }
}

function* unloadPhysicalCardRequest(action: Action) {
  if (CardsActions.unloadPhysicalCard.request.match(action)) {
    try {      
      const unloadPhysicalCardResponse: ApiResponse<UnloadPhysicalCardResponse> = yield call(
        api.unloadPhysicalCardPost,
        action.payload
      );
      if (unloadPhysicalCardResponse.ok && unloadPhysicalCardResponse.data?.success) {
        const responseData = unloadPhysicalCardResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(CardsActions.unloadPhysicalCard.success(responseData.value));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info: unloadPhysicalCardResponse?.data?.message ?? "Error",
          type: "error",
        });

        throw new RequestErrorFromResponse(unloadPhysicalCardResponse);
      }
    } catch (error) {
      yield put(CardsActions.unloadPhysicalCard.failure(error));
    }
  }
}

const cardCommissionsRequest = sagaFactory<CardCommissionsResponse>({
  restActions: CardsActions.cardCommissions,
  apiRequest: api.cardCommissionsGet,
  apiResponseValidator: zedApiResponseValidator,
  apiResponseDataTransformer: zedApiResponseDataTransformer,
  cachingOptions: {
    getFromLocalStorageFn: getCardCommissionsFromLocalStorage,
    storeToLocalStorageFn: storeCardCommissionsToLocalStorage,
    successFn: CardsActions.cardCommissions.success,
    dataKey: "commissions",
    cacheLifetime: 12
  }
});

export function* CardsSaga() {
  yield* [
    takeLatest(CardsActions.getMyCards.request.type, getMyCardsRequest),
    takeLeading(
      CardsActions.createVirtualCard.request.type,
      createVirtualCardRequest
    ),
    takeLeading(
      CardsActions.createVisaCard.request.type,
      createVisaCardRequest
    ),
    takeLeading(
      CardsActions.getTransactions.request.type,
      getTransactionsRequest
    ),
    takeLeading(
      CardsActions.allowedCountries.request.type,
      allowedCountriesRequest
    ),
    takeLeading(CardsActions.cardFeatures.request.type, cardFeaturesRequest),
    takeLatest(
      CardsActions.issueVirtualCard.request.type,
      issueVirtualCardRequest
    ),
    takeLatest(CardsActions.getCVV.request.type, getCVVRequest),
    takeLeading(
      CardsActions.loadVirtualCard.request.type,
      loadVirtualCardRequest
    ),
    takeLeading(
      CardsActions.loadPhysicalCard.request.type,
      loadPhysicalCardRequest
    ),
    takeEvery(
      CardsActions.cardTransactions.request.type,
      cardTransactionsRequest
    ),
    takeLatest(CardsActions.cardToCard.request.type, cardToCardRequest),
    takeLatest(CardsActions.newHolder.request.type, newHolderRequest),
    takeLatest(CardsActions.submitKyc.request.type, submitKycRequest),
    takeLatest(CardsActions.activate.request.type, activateRequest),
    takeLeading(CardsActions.unloadVirtualCard.request.type, unloadVirtualCardRequest),
    takeLeading(CardsActions.unloadPhysicalCard.request.type, unloadPhysicalCardRequest),
    takeLeading(CardsActions.cardCommissions.request.type, cardCommissionsRequest)
  ];
}
