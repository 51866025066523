import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './NewPasswordStyles';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FormTitle, SmallLink, SmallText } from '../Login/LoginPageStyles';
import { SupportButton } from '../../components/SupportButton';
import { TextInput } from '../../components/TextInput';
import { CustomButton } from '../../components/CustomButton';
import { useHideErrors } from '../../hooks/useHideErrors';
import { useThemeContext } from '../../theme';
import { ValidationInfo } from '../../components/ValidationInfo';
import { passwordValidations, validateEmail } from '../../helpers/validationHelper';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../logic/user/UserRedux';
import { userSelector } from '../../logic/user/UserSelectors';

export type NewPasswordProps = {};

const NewPassword: React.FC<NewPasswordProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const params = useParams();

  const {
    userEmail,
    resetPassword: {
      fetching: fetchingResetPassword
    }
  } = useSelector(userSelector);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { hideErrors, setHideErrors, onInputErrorAnimationEnd } = useHideErrors();

  const prefillEmailIfPossible = () => {
    if (userEmail)
      setEmail(userEmail);
  };

  const onPressRemembered = useCallback(() => {
    navigate("/", { replace: true });
  }, []);

  const onPressReset = () => {
    if (
      validateEmail(email) &&
      passwordValidations.validatePassword(password) &&
      passwordValidations.validatePassword(confirmPassword) &&
      passwordValidations.validatePasswordsMatch(password, confirmPassword)
    ) {
      dispatch(UserActions.setUserEmail(email));
      dispatch(UserActions.resetPassword.request({
        code: params?.id ?? "",
        new_password: password,
        successCallback: () => navigate("/", { replace: true }),
      }));
    } else {
      setHideErrors(false);
    }
  };

  const resetButtonDisabled = useMemo(() => {
    return (
      !email ||
      !passwordValidations.validatePassword(password) ||
      !passwordValidations.validatePassword(confirmPassword) ||
      !passwordValidations.validatePasswordsMatch(password, confirmPassword)
    )
  }, [email, password, confirmPassword]);

  // ComponentDidMount
  useEffect(() => {
    prefillEmailIfPossible();
  }, []);

  // useEffect(() => {
  //   console.log("params", params);
  // }, [params]);

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <FormTitle>{t("newPasswordPage.resetYourPassword")}</FormTitle>
        <Box className={cn(s.rowBetween)}>
          <SmallText>{t("registerScreen.pleaseFill")}</SmallText>
          <SupportButton />
        </Box>
        <Box className={cn(s.remembered)}>
          <SmallLink onClick={onPressRemembered}>
            {t("resetPasswordPage.rememberedPassword")}
          </SmallLink>
        </Box>

        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.inputsContainer)}>

            <TextInput
              value={email}
              placeholder={t("email") as string}
              onChangeText={(text) => setEmail(text)}
              validationOk={hideErrors || validateEmail(email)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />

            <TextInput
              value={password}
              placeholder={t("newPassword") as string}
              onChangeText={(text) => setPassword(text)}
              secure={true}
              validationOk={
                hideErrors || passwordValidations.validatePassword(password)
              }
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
            <TextInput
              value={confirmPassword}
              placeholder={t("confirmNewPassword") as string}
              onChangeText={(text) => setConfirmPassword(text)}
              secure={true}
              validationOk={
                hideErrors ||
                passwordValidations.validatePasswordsMatch(
                  password,
                  confirmPassword
                )
              }
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />

            <Box className={cn(s.validationsContainer)}>
              <ValidationInfo
                validations={[
                  {
                    id: "v0",
                    text: t("passwordsMustMatch"),
                    ok: passwordValidations.validatePasswordsMatch(
                      password,
                      confirmPassword
                    ),
                  },
                  {
                    id: "v1",
                    text: t("passwordOneNumber"),
                    ok: passwordValidations.validateOneNumber(password),
                  },
                  {
                    id: "v2",
                    text: t("passwordOneUppercase"),
                    ok: passwordValidations.validateOneUppercase(password),
                  },
                  {
                    id: "v3",
                    text: t("passwordLengthMin"),
                    ok: passwordValidations.validatePasswordMinLength(password),
                  },
                ]}
              />
            </Box>
          </Box>

          <CustomButton
            title={t("newPasswordPage.resetPassword") as string}
            bgColorActive={theme.colors.yellowMain}
            bgColorUnactive={theme.colors.shark}
            defaultSizing
            onPress={onPressReset}
            spinner={fetchingResetPassword}
            disabled={resetButtonDisabled}
            marginBottom={theme.metrics.x12}
          />
        </Box>
      </Box>
    </Box>
  );
};

NewPassword.defaultProps={}

const MemorizedComponent = memo(NewPassword);
export { MemorizedComponent as NewPassword };