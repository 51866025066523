import { Text } from "../../components/Text/Text";
import { useThemeContext } from "../../theme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Line, Wrapper, useStyles } from "./MainPageStyles";
import { NewsItem } from "./../../components/NewsItem";
import { userSelector } from "../../logic/user/UserSelectors";
import { socialSelector } from "../../logic/social/SocialSelectors";
import { Box } from "@material-ui/core";
import { handleOpenLink } from "../../helpers/windowHelpers";
import { useVerified } from "../../hooks/useVerified";
import NeedVerification from "./NeedVerification/NeedVerification";
import cn from "classnames";
import { CONFIG } from "../../config";

const Main = () => {
  const s = useStyles();
  const { theme } = useThemeContext();
  // @ts-ignore
  const { t } = useTranslation();

  const {
    whoAmI: { data: user },
  } = useSelector(userSelector);

  const {
    blog: { data: blog },
    news: { data: newsList },
  } = useSelector(socialSelector);

  const userFullyVerified = useVerified();

  return (
    <>
      <Wrapper>
        {user && !userFullyVerified && (
          <>
            <NeedVerification />
            <Line />
          </>
        )}

        {newsList?.value && newsList.value.length > 0 && (
          <>
            <Text
              color={theme.colors.white}
              size="32px"
              fontStyle={theme.fonts.f600}
            >
              {t("mainPage.newFeatures")}
            </Text>
            <Box className={cn(s.horizontalScrollCont)}>
              {newsList.value.map((item: any) => {
                return (
                  <NewsItem
                    {...item}
                    key={item.id}
                    gradient={["#E4EfE9", "#93A5CF"]}
                    subTitle={item.category.name}
                    blurText={item.description}
                    onPress={() => handleOpenLink(item.link)}
                  />
                );
              })}
            </Box>
            <Line />
          </>
        )}

        {blog?.value && blog.value.length > 0 && (
          <>
            <Text
              color={theme.colors.white}
              size="32px"
              fontStyle={theme.fonts.f600}
            >
              {t("mainPage.blog")}
            </Text>
            <Box className={cn(s.horizontalScrollCont)}>
              {blog.value.slice(0, 10).map((item: any) => {
                return (
                  <NewsItem
                    {...item}
                    key={item.id}
                    ad
                    image={`${CONFIG.ZED_BASE_URL}${CONFIG.ZED_ASSETS_PATH.FILES_UPLOAD}${item.poster}`}
                    gradient={["#FFECD2", "#FCB69F"]}
                    subTitle={item.category.name}
                    blurText={item.summary}
                    onPress={() => handleOpenLink(item.link)}
                  />
                );
              })}
            </Box>
            <Line />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Main;
