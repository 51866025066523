import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useStyles } from './OfflineWithdrawStyles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cryptoSelector } from '../../../../../logic/crypto/CryptoSelectors';
import { userSelector } from '../../../../../logic/user/UserSelectors';
import { generalSelector } from '../../../../../logic/general/GeneralSelectors';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { useCountryText } from '../../../../../hooks/useCountryText';
import { UserActions } from '../../../../../logic/user/UserRedux';
import { CryptoActions } from '../../../../../logic/crypto/CryptoRedux';
import { GeneralActions } from '../../../../../logic/general/GeneralRedux';
import { validate2FACode, validateBIC, validateBankAccountNumber, validateBankName, validateEmail, validateFloat, validateIBAN, validateName, validatePhone } from '../../../../../helpers/validationHelper';
import { CURRENCY_DATA, CURRENCY_LABEL } from '../../../../../data/currency';
import { WalletItem } from '../../../../../components/WalletItem';
import { CONFIG } from '../../../../../config';
import { prettyStringToNumber, stringOrFallback } from '../../../../../helpers/formatHelpers';
import { useThemeContext } from '../../../../../theme';
import { CustomButton } from '../../../../../components/CustomButton';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import { StringValueItem } from '../../../../../components/StringValueItem';
import { SettingsItem } from '../../../../../components/SettingsItem';
import { ShowDetails } from '../../../../../components/ShowDetails';
import { VerificationRequired } from '../../../../../components/VerificationRequired';
import { useDependentValue, useSearchValue } from '../../../../../helpers/customHooks';
import { useVerified } from '../../../../../hooks/useVerified';
import { Bank, DataPickerItemType, LocalBank, TransactionType } from '../../../../../types/types';
import cn from 'classnames';
import { foundСoincidences } from '../../../../../helpers/strings';

export type OfflineWithdrawProps = {};

const OfflineWithdraw: React.FC<OfflineWithdrawProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const {
    remit: {
      fetching: fetchingRemit
    },
    paymentsPageSelectedAccount: accountData
  } = useSelector(cryptoSelector);

  const {
    whoAmI: {
      data: profile,
      fetching: fetchingProfile
    }
  } = useSelector(userSelector);

  const {
    localBanks: {
      data: localBanks,
      fetching: fetchingLocalBanks
    },
    searchBanks: {
      data: foundBanks,
      fetching: fetchingSearchBanks
    },
    swiftCountries: {
      data: swiftCountries
    },
    listCountries: {data: countriesData},
    withdrawTypes: {data: withdrawTypesData}
  } = useSelector(generalSelector);

  const WITHDRAW_TYPE_DATA = useMemo(() => [
    {
      value: "local",
      label: t("offlineWithdrawPage.localBankTransfer"),
    },
    {
      value: "international",
      label: t("offlineWithdrawPage.internationalBankTransfer"),
    },
  ], [t]);

  const WITHDRAW_TYPE_LABEL = useMemo(() => ({
    "local": t("offlineWithdrawPage.localBankTransfer"),
    "international": t("offlineWithdrawPage.internationalBankTransfer"),
  }), []);

  const RECEIVER_TYPE_DATA = useMemo(() => [
    {
      value: "personal",
      label: t("offlineWithdrawPage.personal"),
    },
    {
      value: "organization",
      label: t("offlineWithdrawPage.organization"),
    },
  ], [t]);

  const RECEIVER_TYPE_LABEL = useMemo(() => ({
    "personal": t("offlineWithdrawPage.personal"),
    "organization": t("offlineWithdrawPage.organization"),
  }), []);

  const [withdrawType, setWithdrawType] = useState<"local" | "international">(WITHDRAW_TYPE_DATA[1].value as "international");
  const [receiverType, setReceiverType] = useState<"personal" | "organization">(RECEIVER_TYPE_DATA[0].value as "personal");
  const [paymentType, setPaymentType] = useState<TransactionType | string | undefined>('Wire Transfer');
  const [bankName, bankNameInner, setBankNameInner] = useSearchValue("");
  const [selectedBankId, setSelectedBankId] = useState(-1);
  const [bic, setBic] = useState("");
  const [iban, setIban] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [message, setMessage] = useState("");
  const [code2FA, setCode2FA] = useState("");
  const [firstName,setFirstName] = useState(profile?.first_name ?? '')
  const [secondName,setSecondName] = useState(profile?.last_name ?? '')
  const [countryId,setCountryId] = useState('')
  const [phoneNumber,setPhoneNumber] = useState(profile?.mobile ?? '')
  const [email,setEmail] = useState(profile?.email ?? '')
  const [registrationNumber, setRegistrationNumber] = useState<string>('')

  const userFullyVerified = useVerified();
  
  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const {
    amount,
    setAmount,
    handleAmountInput
  } = useAmountInput();

  const countryText = useCountryText(profile);

  const swiftCountryId = useDependentValue(() => {
    if (!profile?.country || !swiftCountries)
      return "";
    
    const swiftCountryData = swiftCountries.countries.find((c) => c.Country_Code === profile.country?.alpha_2);
    if (!swiftCountryData)
      return "";

    return swiftCountryData.id.toString();
  }, [profile, swiftCountries]);

  const handleRequestWithdrawal = () => {
    if (!profile || !accountData)
      return;

    if (validateFloat(amount) && validateBankName(bankName) && validateBIC(bic) && validateIBAN(iban) && validateBankAccountNumber(accountNumber) && validate2FACode(code2FA)) {
      dispatch(CryptoActions.remit.request({
        from_account_id: accountData.id,
        amount: Number(amount),
        type: "Withdraw_Requested",
        otp_code: code2FA,
        bank_transfer: {
          country: countriesData?.countriesTable[countryId as any] ?? "",
          currency: accountData.currency_code,
          payment_type: withdrawType === "local"?'Local Bank':!paymentType?'Wire Transfer':paymentType.replace(' ','_'),
          receiver_type:receiverType,
          full_name: `${firstName} ${secondName}`,
          mobile: phoneNumber,
          email: email,
          reg_number: accountNumber,
          phone: "",
          bank_name: bankName,
          branch_name: "",
          BIC: bic,
          IBAN: iban,
          message,
          proforma: ""
        }
      }));
    } else {
      setHideErrors(false);
    }
  }

  const handleUserSelectedBank = useCallback((bank: DataPickerItemType & (Bank | LocalBank)) => {
    setSelectedBankId(bank.value as number);
    setBankNameInner(bank.label);

    if ('swift_code' in bank) {
      setBic(bank.swift_code);
    }
  }, [])

  // ComponentDidMount
  useEffect(() => {
    dispatch(GeneralActions.swiftCountries.request());
    dispatch(GeneralActions.withdrawTypes.request());
    dispatch(GeneralActions.listCountries.request())
  }, []);

  useEffect(() => {
    if (!profile)
      dispatch(UserActions.whoAmI.request());
  }, [profile]);

  useEffect(() => {
    if (bankName && swiftCountryId)
      dispatch(GeneralActions.searchBanks.request({
        swift_country_id: swiftCountryId,
        query: bankName
      }));
  }, [bankName, swiftCountryId]);

  useEffect(() => {
      dispatch(GeneralActions.localBanks.request({
        country_id: countryId.toString(),
        currency_code: accountData?.currency_code ?? ''
      }));
  }, [countryId]);

  const localBankCountries = useMemo(() => {
    if(localBanks){
      return localBanks.map((item) => {
        return {...item,value:item.id,label:item.name }
      })
    }
  },[localBanks])

  const isLocalTransfer = useMemo(() => {
    return withdrawType === "local"
  },[withdrawType])


  const bankVariantsData = useMemo(() => {
    if(bankName.length > 3){
    if (withdrawType === "local" && localBanks) {

      const filteredBanks = localBanks.find((itm) => itm.id === Number(countryId))
      return (filteredBanks?.local_banks??[]).filter((bank) => foundСoincidences(bank.bank_name, bankName)).map((bank) => ({...bank, label: bank.bank_name, value: bank.id}));
    }
    return (foundBanks ?? []).map((bank) => ({...bank, label: bank.bank, value: bank.id}));
  }
  }, [withdrawType, foundBanks, localBanks, bankName]);

  const paymentTypesFiltered = useMemo(() => {
    if(withdrawTypesData)
    return withdrawTypesData.filter((item) => item.value !== 'Local Bank')
  },[withdrawTypesData])

  if (!userFullyVerified)
    return <VerificationRequired />;

  if (!profile || fetchingProfile || !accountData)
    return null;

  return (
    <Box className={cn(s.globalContainer)}>
      <Box className={cn(s.container)}>
        <Box className={cn(s.spaceBetween)}>
          <Box className={cn(s.topBlock)}>

            <p className={cn(s.whiteTextSemiBold)}>
              {t('from')}
            </p>
            
            <WalletItem 
              image={`${CONFIG.ZED_BASE_URL}/${accountData.currency_flag}`}
              userHasThisWallet={true}
              // userCurrencySymbol={getCurrencySymbol(userCurrency)}
              userCurrencySymbol="$"
              balance={accountData.balance * prettyStringToNumber(accountData.currency_price)}
              cryptoBalance={accountData.balance}
              name={accountData.currency_code}
              walletName={accountData.name}
              marginVertical={theme.metrics.x3}
            />
            
            <Box className={cn(s.inputsContainer)}>
            <TextInputUnderlined
              value={amount} 
              placeholder={t("amount") as string}
              onChangeText={(text) => handleAmountInput(text, setAmount)} 
              validationOk={hideErrors || validateFloat(amount)}
            />

            {countriesData?.countries && (
              <SettingsItem
                label={t('country')}
                paddingRight={0}
                paddingLeft={0}
                labelColor={theme.colors.greySubText}
                rightLabel={countriesData.countriesTable[countryId as any]}
                onValueChange={v => setCountryId(`${v}`)}
                picker={true}
                items={isLocalTransfer?localBankCountries:countriesData.countries}
                value={parseInt(countryId)}
              />
            )}

            <StringValueItem 
              label={t('currency')}
              value={accountData.currency_code}
            />

            <TextInputUnderlined
              value={firstName} 
              rightLabel={t("profileDetailsPage.firstName") ?? ''}
              placeholder=''
              onChangeText={(text) => setFirstName(text)} 
              validationOk={hideErrors || validateName(firstName)}
            />

            <TextInputUnderlined
              value={secondName} 
              rightLabel={t("profileDetailsPage.lastName") ?? ''}
              placeholder=''
              onChangeText={(text) => setSecondName(text)} 
              validationOk={hideErrors || validateName(secondName)}
            />

            <TextInputUnderlined
              value={phoneNumber} 
              rightLabel={t("profileDetailsPage.phoneNumber") ?? ''}
              placeholder=''
              onChangeText={(text) => setPhoneNumber(text)} 
              validationOk={hideErrors || validatePhone(phoneNumber)}
            />

            <TextInputUnderlined
              value={email} 
              rightLabel={t("profileDetailsPage.email") ?? ''}
              placeholder=''
              onChangeText={(text) => setEmail(text)} 
              validationOk={hideErrors || validateEmail(phoneNumber)}
            />
  <>{console.log(paymentTypesFiltered,'')}</>
            {paymentTypesFiltered && <SettingsItem
              label={`${t("paymentType")}:`}
              labelColor={theme.colors.greySubText}
              rightLabel={withdrawType === "local"?'Local Bank':paymentType}
              onValueChange={(v) => setPaymentType(v as any)}
              picker={true}
              items={paymentTypesFiltered}
              value={paymentType}
              disabled={withdrawType === "local"}
              rLabelMarginRight={0}
              paddingRight={0}
              paddingLeft={0}
              rLabelColor={theme.colors.yellowMain}
            />}

            <SettingsItem
              label={`${t("receiverType")}:`}
              labelColor={theme.colors.greySubText}
              rightLabel={RECEIVER_TYPE_LABEL[receiverType]}
              onValueChange={(v) => setReceiverType(v as any)}
              picker={true}
              items={RECEIVER_TYPE_DATA}
              value={receiverType}
              rLabelMarginRight={0}
              paddingRight={0}
              paddingLeft={0}
              rLabelColor={theme.colors.yellowMain}
            />

            {receiverType === 'organization' && <TextInputUnderlined
              value={registrationNumber}
              placeholder={t('registrationNumber')??''}
              onChangeText={text => setRegistrationNumber(text)}
              validationOk={hideErrors || registrationNumber.length> 2}
            />}

            <SettingsItem
              label={`${t("transferType")}:`}
              labelColor={theme.colors.greySubText}
              rightLabel={WITHDRAW_TYPE_LABEL[withdrawType]}
              onValueChange={(v) => setWithdrawType(v as any)}
              picker={true}
              items={WITHDRAW_TYPE_DATA}
              value={withdrawType}
              rLabelMarginRight={0}
              paddingRight={0}
              paddingLeft={0}
              rLabelColor={theme.colors.yellowMain}
            />

            <Box>
              <TextInputUnderlined
                value={bankNameInner}
                placeholder={t('offlineWithdrawPage.bankName')??''}
                onChangeText={text => setBankNameInner(text)}
                validationOk={hideErrors || validateBankName(bankName)}
                picker={true}
                customPickerField={isLocalTransfer}
                items={isLocalTransfer?bankVariantsData as any:bankVariantsData}
                pickerValue={selectedBankId}
                onPickerValueChange={(v) => handleUserSelectedBank(v as any)}
                loadingPickerItems={withdrawType === "local" && fetchingLocalBanks || withdrawType === "international" && fetchingSearchBanks}
              />
            </Box>
            <TextInputUnderlined
              value={bic}
              placeholder={"BIC"}
              onChangeText={text => setBic(text)}
              validationOk={hideErrors || validateBIC(bic)}
            />
            <TextInputUnderlined
              value={iban}
              placeholder={"IBAN"}
              onChangeText={text => setIban(text)}
              validationOk={hideErrors || validateIBAN(iban)}
            />

            <TextInputUnderlined
              value={accountNumber}
              placeholder={t('offlineWithdrawPage.accountNumber') as string}
              onChangeText={text => setAccountNumber(text)}
              validationOk={hideErrors || validateBankAccountNumber(accountNumber)}
            />

            <TextInputUnderlined
              value={message}
              placeholder={t("offlineWithdrawPage.message") as string}
              onChangeText={text => setMessage(text)}
              validationOk={true}
            />

            <TextInputUnderlined
              value={code2FA} 
              placeholder={t("twoFACode") as string}
              onChangeText={(text) => setCode2FA(text)} 
              validationOk={hideErrors || validate2FACode(code2FA)}
            />

              <ShowDetails marginBottom={0}>
                <StringValueItem 
                  label={t("payments.operationTime")}
                  value={t("defaultConfirmOperationTime")}
                />
              </ShowDetails>
            </Box>
          </Box>

          <CustomButton
            title={t("offlineWithdrawPage.requestWithdrawal") as string}
            bgColorActive={theme.colors.yellowMain}
            colorActive={theme.colors.black}
            defaultSizing
            onPress={handleRequestWithdrawal}
            spinner={fetchingRemit}
          />
        </Box>
      </Box>
    </Box>
  );
};

OfflineWithdraw.defaultProps={}

export { OfflineWithdraw };