import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 14px;
  background-color: ${(p) => p.theme.colors.black};
`;
export const ImageLogo = styled.img`
  width: 170px;
  border-radius: 24px;
  margin: 0 auto 20px;
  @media (max-width: 900px) {
    width: 120px;
    height: 120px;
    border-radius: 16px;
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.colors.subBackground};
  margin-top: 20px;
`;
