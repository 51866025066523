import React, { memo } from "react";
import { useStyles } from "./Page404Styles";
import { Box, Typography } from "@material-ui/core";
import { CustomButton } from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

export type Page404Props = {};

const Page404: React.FC<Page404Props> = ({}) => {
  const navigate = useNavigate();
  const s = useStyles();

  return (
    <Box className={cn(s?.container)}>
      <Box className={cn(s?.center)}>
        <Typography variant="h3" paragraph className={cn(s?.whiteText)}>
          Sorry, page not found!
        </Typography>
        <Box className={cn(s?.button)}>
          <CustomButton
            title={"Go to home"}
            onPress={() => navigate("/main")}
            colorActive={"white"}
            bgColorActive={"#2f8af5"}
            fontSize={18}
            height={56}
          />
        </Box>
      </Box>
    </Box>
  );
};

Page404.defaultProps = {};

const MemorizedComponent = memo(Page404);
export { MemorizedComponent as Page404 };
