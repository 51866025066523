import React, { memo } from "react";
import { useStyles } from "./StoriesItemStyles";
import { Box } from "@material-ui/core";
import cn from "classnames";
import { theme } from "../../theme/default/theme";

export type StoriesItemProps = {
  viewed?: boolean;
  borderColorNew?: string;
  title?: string;
  image: string;
  imageSize?: number;
  onPress: () => void;
};

const StoriesItem: React.FC<StoriesItemProps> = ({
  viewed,
  borderColorNew,
  title,
  image,
  imageSize,
  onPress,
}) => {
  const s = useStyles();

  return (
    <Box
      className={cn(s.container)}
      style={{ width: (imageSize as number) + 20 }}
    >
      <Box
        className={cn(s.outerCircle)}
        style={{
          width: imageSize,
          height: imageSize,
          borderRadius: (imageSize as number) * 0.5,
          border: viewed ? undefined : `1px solid ${borderColorNew}`,
        }}
      >
        <Box
          className={cn(s.innerCircle)}
          style={{ borderRadius: (imageSize as number) * 0.5 }}
          onClick={onPress}
        >
          <img src={image} className={cn(s.image)} style={{objectFit:'cover'}}  alt="stories img" />
        </Box>
      </Box>

      {title && <p className={cn(s.titleText)}>{title}</p>}
    </Box>
  );
};

StoriesItem.defaultProps = {
  viewed: false,
  borderColorNew: theme.colors.dodgerBlue,
  imageSize: 60,
};

const MemorizedComponent = memo(StoriesItem);
export { MemorizedComponent as StoriesItem };
