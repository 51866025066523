import React, { useCallback, useState, useEffect } from 'react';
import { useStyles } from './ExchangeStyles';
import { Box, CircularProgress } from '@material-ui/core';
import { SelectInput } from "./../../Components";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../../../../../logic/user/UserSelectors';
import { cryptoSelector } from '../../../../../logic/crypto/CryptoSelectors';
import { CryptoAccount, CryptoAccountDropdownItem, CryptoActions } from '../../../../../logic/crypto/CryptoRedux';
import { WalletItem } from '../../../../../components/WalletItem';
import { CustomButton } from '../../../../../components/CustomButton';
import { TextInputUnderlined } from '../../../../../components/TextInputUnderlined';
import { CONFIG } from '../../../../../config';
import { useThemeContext } from '../../../../../theme';
import { prettyStringToNumber } from '../../../../../helpers/formatHelpers';
import { ContentBox } from '../../../../../components/ContentBox/ContentBox';
import { Text } from "../../../../../components/Text/Text";
import { text } from "../../../../../theme/text";
import { validateFloat, validate2FACode } from '../../../../../helpers/validationHelper';
import { useDependentState, useDependentValue, useSearchValue } from '../../../../../helpers/customHooks';
import { useHideErrors } from '../../../../../hooks/useHideErrors';
import { useAmountInput } from '../../../../../hooks/useAmountInput';
import { exchangeCalculateEstimateBuy, getAdaptivePriceFormatter } from '../../../../../helpers/price';
import { useVerified } from '../../../../../hooks/useVerified';
import { VerificationRequired } from '../../../../../components/VerificationRequired';
import SwapIcon from './../../../../../assets/icons/swap';
import useWindowWidth from '../../../../../hooks/useWindowWidth';
import cn from 'classnames';

enum ExchangeSteps {
  EstimatePreview = 0,
  CheckOnly
};

const DEFAULT_SELL_VALUE = "1";

export type ExchangeProps = {};

const Exchange: React.FC<ExchangeProps> = ({}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const {
    enabled2FA,
  } = useSelector(userSelector);

  const {
    getAccountsList: {
      fetching: fetchingMyAccounts
    },
    transferCheckOnlyResult,
    transferCheckOnlyFetching,
    transfer: {
      fetching: fetchingTransfer
    },
    swapReceive: {
      fetching: swapReceiveFetching
    },
    exchangeScreenAccounts: dropdownData,
    swapReceiveTable,
    exchangeFromCoin,
    exchangeToCoin
  } = useSelector(cryptoSelector);

  const [sellValue, sellInnerValue, setSellValue] = useSearchValue(DEFAULT_SELL_VALUE);
  const [buyValue, buyInnerValue, setBuyValue] = useSearchValue('0');
  const [code2FA, setCode2FA] = useState("");

  const [exchangeStep, setExchangeStep] = useDependentState(() => ExchangeSteps.EstimatePreview, [sellValue, exchangeFromCoin, exchangeToCoin]);

  const userFullyVerified = useVerified();

  const {
    hideErrors,
    setHideErrors,
    onInputErrorAnimationEnd
  } = useHideErrors();

  const {
    handleAmountInput
  } = useAmountInput();

  const onSwapPress = useCallback(() => {
    dispatch(CryptoActions.swapFromAndTo());
  }, []);

  const transferRequest = useCallback((check_only: boolean = false) => {
    dispatch(CryptoActions.transfer.request({
      // @ts-expect-error
      from_account_id: exchangeFromCoin.id,
      // @ts-expect-error
      to_account_id: exchangeToCoin.id,
      deposit_address: "",
      to_blockchain: "none",
      amount: Number(sellInnerValue),
      otp_code: check_only ? undefined : code2FA,
      check_only,
      normal_sending: false,
      successCallback: check_only ? undefined : () => {
        setExchangeStep(ExchangeSteps.EstimatePreview);
        setSellValue(DEFAULT_SELL_VALUE);
        setCode2FA("");
      }
    }));
  }, [exchangeFromCoin, exchangeToCoin, sellInnerValue, code2FA]);

  const onExchangePress = useCallback(() => {
    if (validateFloat(sellInnerValue) && (!enabled2FA || exchangeStep === ExchangeSteps.EstimatePreview || validate2FACode(code2FA))) {
      if (exchangeStep === ExchangeSteps.EstimatePreview) {
        transferRequest(true);
        setExchangeStep(ExchangeSteps.CheckOnly);
      } else {
        transferRequest();
      }
    } else  
      setHideErrors(false);
  }, [exchangeStep, sellInnerValue, enabled2FA, code2FA]);

  useEffect(() => {
    if (transferCheckOnlyResult) {
      setBuyValue(transferCheckOnlyResult.to_amount);
    }
  }, [transferCheckOnlyResult]);

  useEffect(() => {
    if (Number(sellValue) === 0) 
      setBuyValue("0");
    else if (exchangeFromCoin && exchangeToCoin) {
      if (swapReceiveTable[exchangeFromCoin.id] === undefined) {
        dispatch(CryptoActions.swapReceive.request({
          pay_account_id: exchangeFromCoin.id
        }));
      } else {
        setBuyValue(
          exchangeCalculateEstimateBuy({
            sellValue,
            exchangeFromCoinId: exchangeFromCoin.id,
            exchangeToCoinId: exchangeToCoin.id,
            swapReceiveTable
          })
        )
      }
      // transferRequest(true);
    }
  }, [exchangeFromCoin, exchangeToCoin, sellValue, swapReceiveTable]);

  const toCoinRateText = useDependentValue(() => {
    if (!exchangeFromCoin || !exchangeToCoin)
      return "-";

    if (exchangeStep === ExchangeSteps.EstimatePreview) {
      if (!swapReceiveTable[exchangeFromCoin.id])
        return `- ${exchangeFromCoin.currency_code}`;
      
      const rateFromTo = swapReceiveTable[exchangeFromCoin.id].find((v) => v.id === exchangeToCoin.id);
      return `${rateFromTo ? getAdaptivePriceFormatter(Number(rateFromTo.backRate), "").formattedPrice : "-"} ${exchangeFromCoin.currency_code}`;
    }

    return `${transferCheckOnlyResult?.backRate ? getAdaptivePriceFormatter(Number(transferCheckOnlyResult.backRate), "").formattedPrice : "-"} ${exchangeFromCoin.currency_code}`;
  }, [exchangeStep, swapReceiveTable, transferCheckOnlyResult, exchangeFromCoin, exchangeToCoin]);

  const operationFeeText = useDependentValue(() => {
    if (!exchangeFromCoin)
      return "-";

    if (exchangeStep === ExchangeSteps.EstimatePreview)
      return t("payments.pressPreviewToCalculate");

    return `${transferCheckOnlyResult?.from_commission ? getAdaptivePriceFormatter(Number(transferCheckOnlyResult.from_commission), "").formattedPrice : "-"} ${exchangeFromCoin.currency_code}`;
  }, [exchangeStep, t, transferCheckOnlyResult, exchangeFromCoin]);

  if (!userFullyVerified)
    return <VerificationRequired />;

  if (!dropdownData.length || !exchangeFromCoin || !exchangeToCoin)
    return null;

  return (
    <>
      <div style={{ marginBottom: "40px" }}>
        <SelectInput 
          label={t("payments.from")} 
          data={dropdownData}
          value={exchangeFromCoin as CryptoAccountDropdownItem}
          onValueChange={(v) => dispatch(CryptoActions.setExchangeFromCoin(v as CryptoAccountDropdownItem))}
          rightText={`${dropdownData.length} ${t('wallets')}`}
          editable={false}
          renderItem={(account: CryptoAccount, onPress) => (
            <WalletItem 
              image={`${CONFIG.ZED_BASE_URL}/${account.currency_flag}`}
              userHasThisWallet={true}
              // userCurrencySymbol={getCurrencySymbol(userCurrency)}
              userCurrencySymbol="$"
              balance={account.balance * prettyStringToNumber(account.currency_price)}
              cryptoBalance={account.balance}
              name={account.currency_code}
              walletName={account.name}
              marginVertical={theme.metrics.x3}
              onPress={onPress}
              paddingLeft={theme.metrics.x4}
            />
          )}
        />
      </div>
      <SelectInput 
        label={t("payments.to")} 
        data={dropdownData}
        value={exchangeToCoin as CryptoAccountDropdownItem}
        onValueChange={(v) => dispatch(CryptoActions.setExchangeToCoin(v as CryptoAccountDropdownItem))}
        rightText={`${dropdownData.length} ${t('wallets')}`}
        renderItem={(account: CryptoAccount, onPress) => (
          <WalletItem 
            image={`${CONFIG.ZED_BASE_URL}/${account.currency_flag}`}
            userHasThisWallet={true}
            // userCurrencySymbol={getCurrencySymbol(userCurrency)}
            userCurrencySymbol="$"
            balance={account.balance * prettyStringToNumber(account.currency_price)}
            cryptoBalance={account.balance}
            name={account.currency_code}
            walletName={account.name}
            marginVertical={theme.metrics.x3}
            onPress={onPress}
            paddingLeft={theme.metrics.x4}
          />
        )}
      />

      <ContentBox
        direction="column"
        padding={windowWidth < 700 ? "16px 12px 26px" : "16px 16px 25px 16px"}
        margin="60px 0 0 0"
      >
        <Box className={cn(s.inputsRow)}>
          <Box className={cn(s.inputCont)}>
            <p className={cn(s.topLabel)}>
              {t("youPay")}
            </p>
            
            <TextInputUnderlined
              value={sellInnerValue} 
              placeholder={'0'}
              rightLabel={exchangeFromCoin.currency_code}
              rightLabelFontSize={24}
              fontSize={sellInnerValue?.length > 4 ? 21 : 28}
              onChangeText={(text) => handleAmountInput(text, setSellValue)}
              validationOk={hideErrors || validateFloat(sellInnerValue)}
              // validationOk={false}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
              justifyContent={'center'}
              maxLength={10}
            />
          </Box>

          <Box className={cn(s.swapBtn)}>
            <CustomButton 
              icon={<SwapIcon />}
              onPress={onSwapPress}
              bgColorActive={theme.colors.transparent}
            />
          </Box>

          <Box className={cn(s.inputCont)}>
            <p className={cn(s.topLabel)}>
              {t("youGet")}
            </p>

            <TextInputUnderlined
              value={buyInnerValue} 
              placeholder={'0'}
              rightLabel={exchangeToCoin.currency_code}
              // shouldHideRightLabel={buyValue?.length > 4}
              rightLabelFontSize={24}
              fontSize={buyInnerValue?.length > 4 ? 21 : 28}
              onChangeText={(text) => handleAmountInput(text, setBuyValue)} 
              validationOk={hideErrors || validateFloat(buyInnerValue)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
              justifyContent={'center'}
              maxLength={10}
              editable={false}
            />
          </Box>
        </Box>

        <ContentBox
          backColor="none"
          width="100%"
          margin="30px 0 0 0"
          alignItems="flex-start"
        >
          <ContentBox
            rowGap="30px"
            direction="column"
            backColor="none"
            width="100%"
            alignItems="flex-start"
          >
            <Text color={text.colors.gray} size="20px">
              {`${exchangeToCoin.currency_code} ${t('rate')}`}
            </Text>
            <Text color={text.colors.gray} size="22px">
              {t("operationFee")}
            </Text>
            <Text color={text.colors.gray} size="22px">
              {t("operationTime")}
            </Text>
          </ContentBox>
          <ContentBox
            rowGap="30px"
            direction="column"
            backColor="none"
            width="100%"
            alignItems="flex-end"
          >
            {(swapReceiveFetching || transferCheckOnlyFetching) ?
              <CircularProgress size={24} />
            :
              <Text color={theme.colors.white} size="20px">
                {toCoinRateText}
              </Text>
            }
            
            {(transferCheckOnlyFetching) ?
              <CircularProgress size={24} />
            :
              <Text color={theme.colors.white} size="20px">
                {operationFeeText}
              </Text>
            }
            
            <Text color={text.colors.yellow} size="20px">
              {t("payments.instantly")}
            </Text>
          </ContentBox>
        </ContentBox>

        {enabled2FA &&
          <Box className={cn(s.inputsContainer)}>
            <TextInputUnderlined
              value={code2FA} 
              placeholder={t("twoFACode") as string}
              onChangeText={(text) => setCode2FA(text)} 
              validationOk={hideErrors || validate2FACode(code2FA)}
              onErrorAnimationEnd={onInputErrorAnimationEnd}
            />
          </Box>
        }

        <CustomButton
          title={exchangeStep === ExchangeSteps.EstimatePreview ? (t("payments.previewTransaction") as string) : `${t("exchange")} ${exchangeFromCoin.currency_code} → ${exchangeToCoin.currency_code}`}
          bgColorActive={theme.colors.yellowMain}
          bgColorUnactive={theme.colors.shark}
          colorActive={theme.colors.black}
          defaultSizing
          disabled={!exchangeFromCoin || !exchangeToCoin || fetchingMyAccounts}
          onPress={onExchangePress}
          spinner={fetchingTransfer}
          marginTop={theme.metrics.x5}
        />
      </ContentBox>
    </>
  );
};

Exchange.defaultProps={}

export { Exchange };