import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSelector } from "../../logic/user/UserSelectors";
import {
  validate2FACode,
  validateEmail,
  validatePassword,
} from "../../helpers/validationHelper";
import { UserActions } from "../../logic/user/UserRedux";
import { useTranslation } from "react-i18next";
import { showToast } from "../../helpers/alertService";

type AuthSteps = "login" | "register" | "otp";

export const useAuth = () => {
  // @ts-ignore
  const { t } = useTranslation();

  const [pageStep, setPageStep] = useState<AuthSteps>("login");
  const [inputsData, setInputsData] = useState({
    email: "",
    password: "",
    code2FA: "",
  });
  const { password, email, code2FA } = inputsData;
  const [skip, setSkip] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  // состояние чекбокса "согласия с правилами"
  const [checked, setChecked] = useState(false);
  const swiperRef = useRef<any>(null);
  const swiperRef2 = useRef<any>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    enabled2FA,
    login: { fetching: fetchingLogin },
    registerEmail: { fetching: fetchingRegisterEmail },
  } = useSelector(userSelector);

  // функции для перемещения между слайдами
  const handleSlideChange = (swiper: any) => {
    setTimeout(() => {
      const currentIndex = swiper.activeIndex;
      if (currentIndex <= 3) {
        setActiveSlideIndex(currentIndex);
      } else {
        setActiveSlideIndex(3); // Установка максимального значения
      }
    }, 0);
  };

  const handlePrevSlide = () => {
    swiperRef.current?.slidePrev();
    swiperRef2.current?.slidePrev();
  };

  const handleNextSlide = () => {
    swiperRef.current?.slideNext();
    swiperRef2.current?.slideNext();
  };

  const onPressCreateAccount = () => {
    if (validateEmail(email)) {
      dispatch(
        UserActions.registerEmail.request({
          email,
          platform: "web",
          successCallback: () => setPageStep("otp"),
        })
      );
    } else {
      setErrors([t("validations.wrongEmailFormat")]);
    }
  };

  const confirm = () => {
    if (pageStep === "login") onPressLogin();
    if (pageStep === "register") onPressCreateAccount();
  };
  // контроль инпутов
  const inputHandler = (e: { target: { name: string; value: string } }) => {
    setInputsData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onPressLogin = () => {
    if (
      validateEmail(email) &&
      // validatePassword(password) &&
      (!enabled2FA || validate2FACode(code2FA))
    ) {
      dispatch(
        UserActions.login.request({
          email,
          password,
          platform: "web",
          otp: enabled2FA ? code2FA : undefined,
          successCallback: () => navigate("/main"),
        })
      );
    }else{
      showToast({
        title: t("Error"),
        info: 'Please check that the fields are entered correctly.',
        type: "error",
      });
    }
  };
  const switchModeHandle = (step: AuthSteps) => {
    setPageStep(step);
    setInputsData({
      email: "",
      password: "",
      code2FA: "",
    });
  };

  return {
    password,
    email,
    code2FA,
    swiperRef,
    swiperRef2,
    skip,
    errors,
    checked,
    pageStep,
    activeSlideIndex,
    fetchingLogin,
    confirm,
    setSkip,
    setErrors,
    setPageStep,
    inputHandler,
    setChecked,
    switchModeHandle,
    handlePrevSlide,
    handleNextSlide,
    handleSlideChange,
    fetchingRegisterEmail,
    enabled2FA,
  };
};
