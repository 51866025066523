import { CodeBud, Instruction, InstructionGroup } from "@appklaar/codebud";
import { NetworkInterceptorXHR } from "@appklaar/codebud/Network/NetworkInterceptorXHR";
import { key } from "./codebudKey";
import { getCardsInstructions, getCryptoInstructions, getGeneralInstructions, getNftInstructions, getSocialInstructions, getStorieInstructions, getUserInstructions } from "./instructions";

export const setup = (store: any) => {
  console.log("Setting up @appklaar/codebud");

  // Declare array of instructions
  const INSTRUCTIONS: (Instruction | InstructionGroup)[] = [
    {
      id: "helloWorld",
      handler: () => {
        console.log("Hello, world!");
      },
    },
    ...getCardsInstructions(store),
    ...getCryptoInstructions(store),
    ...getGeneralInstructions(store),
    ...getNftInstructions(store),
    ...getSocialInstructions(store),
    ...getStorieInstructions(store),
    ...getUserInstructions(store)
  ];

  // Call the init method.
  CodeBud.init(key, INSTRUCTIONS, {
    // mode: CONFIG.RUN_MODE === "DEVELOPMENT" ? "dev" : "prod",
    Interceptor: NetworkInterceptorXHR,
    projectInfo: {
      projectId: "65359e8a41d1edcfcd8c2aff"
    },
    // remoteSettingsAutoUpdateInterval: 6e4
  });

  // Check package state (optionaly)
  console.log("CodeBud init:", CodeBud.isInit);
  console.log("CodeBud state:", CodeBud.state);

  function select(state: any) {
    // return state;
    return {...state, crypto: {...state.crypto, coingeckoMarketCharts: undefined}};
  }

  const unsubscribeCodeBudFromStoreChanges = store.subscribe(
    CodeBud.createReduxStoreChangeHandler(store, select, 750)
  );

  CodeBud.enableLocalStorageMonitor(localStorage);
};
