import React, { memo } from "react";
import { useStyles } from "./MarketVolumeInfoStyles";
import { Box } from "@material-ui/core";
import cn from "classnames";
import { useThemeContext } from "../../theme";
import { useDependentValue } from "../../helpers/customHooks";
import currency from "currency.js";
import { Trand, getAdaptivePriceFormatter } from "../../helpers/price";
import { CONFIG } from "../../config";
import ArrowIcon from "./assets/arrow";

export type MarketVolumeInfoProps = {
  title: string;
  value: number;
  change: number;
};

const MarketVolumeInfo: React.FC<MarketVolumeInfoProps> = ({
  title,
  value,
  change,
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  const valueFormatted = useDependentValue(() => {
    return `${
      getAdaptivePriceFormatter( value / 1e9)
    } B`;
  }, [value]);

  const trand = useDependentValue(
    () => (change < 0 ? Trand.Negative : Trand.Positive),
    [change]
  );

  const trandColor = useDependentValue(
    () => (trand === Trand.Negative ? theme.colors.red : theme.colors.sulu),
    [trand]
  );

  return (
    <Box className={cn(s.container)}>
      <p className={cn(s.greyText)}>{title}</p>
      <p className={cn(s.valueText)}>{valueFormatted}</p>
      <Box className={cn(s.changeContainer)}>
        <Box
          className={cn(s.arrowIcon)}
          style={{
            transform: `rotateZ(${
              trand === Trand.Negative ? `${Math.PI}rad` : "0rad"
            })`,
            marginBottom:
              trand === Trand.Negative ? theme.metrics.x05 : undefined,
          }}
        >
          <ArrowIcon color={trandColor} />
        </Box>
        <p className={cn(s.changeText)} style={{ color: trandColor }}>
          {isFinite(change)
            ? `${currency(Math.abs(change), CONFIG.PERCENT_OPTIONS)}%`
            : "–"}
        </p>
      </Box>
    </Box>
  );
};

MarketVolumeInfo.defaultProps = {};

const MemorizedComponent = memo(MarketVolumeInfo);
export { MemorizedComponent as MarketVolumeInfo };
