import { useSelector } from "react-redux";
import { ContentBox } from "../../components/ContentBox/ContentBox";
import { Text } from "../../components/Text/Text";
import { useThemeContext } from "../../theme";
import { Wrapper } from "./FaqStyles";
import { generalSelector } from "../../logic/general/GeneralSelectors";
import { QAPair } from "../../logic/general/GeneralRedux";
import { parseHtmlToResults } from "./parser";


const Faq = () => {
  const { theme } = useThemeContext();
  const {
    faq: { data: faqData },
  } = useSelector(generalSelector);
  let data = faqData?.value;

  console.log(data)

  if(!data)
  return null;

  const preparedData = parseHtmlToResults(data.content_body);

  return (
    <Wrapper>
      {preparedData.map((el: QAPair, index: number) => (
        <>
          <ContentBox
            width="100%"
            padding="10px"
            margin="34px 0 0 0"
            justyfiContent="flex-start"
            key={index}
          >
            <Text
              color={theme.colors.white}
              fontStyle={theme.fonts.f600}
              size="22px"
            >
              {el.question}
            </Text>
          </ContentBox>
          <Text
            color={theme.colors.textDescription}
            fontStyle={theme.fonts.f500}
            size="16px"
            margin="18px 0 0 0"
          >
            {el.answer}
          </Text>
        </>
      ))}
    </Wrapper>
  );
};
export default Faq;
