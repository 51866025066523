import {
  addAllRestReducers,
  createRestActions,
  getDefaultRestState,
} from '../../store/restHelper';
import {NodeRestStateType} from '../../store/restHelper.d';
import {ActionReducerMapBuilder, createAction, createReducer} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { AssignNumberRestActions, ChangeCallForwardingRestActions, ChangeRenewStatusRestActions, GetSmsRestActions, ListCountriesRestActions, ListNumbersRestActions, NewNumberRestActions, SendCodeRestActions, TopUpRestActions, UnassignNumberRestActions, VerifyCodeRestActions } from './EsimActions';
import { UserInfo } from '../user/UserRedux';

/////Types/////

export type PositiveResponse = {
  status: boolean
}

export type AssignNumberRequest = {
  id: number;
  client_number: string;
}

type ChangeCallForwardingRequest = {
  id: number;
}

export type ChangeRenewStatusRequest = {
  id: number;
  status: 'Enabled' | 'Disabled';
}

type GetSmsRequest = {
  id: number;
}

export type SmsItemType = {
  from_number: string;
  to_number: string;
  delivery_time: string;
  sms_text: string;
}

type GetSmsResponse = SmsItemType[]

export type ListCountryPriceItemType = {
  Period: number;
  Price: number;
}

export type ListCountryItemType = {
  Prices: ListCountryPriceItemType[];
  country_code: string;
  country_name: string;
  country_flag: string;
}

type ListCountriesResponse = ListCountryItemType[]

type ListNumbersRequest = {
  customer_id?: number;
  id?: number;
}

export type NumberInfoType = {
  id: number;
  number: string;
  country_code: string;
  purchase_date: string;
  expiration_date: string;
  free_receiving_seconds: number;
  auto_renew_enabled: boolean;
  renew: number;
  blocked: boolean;
  terminated: boolean;
}

export type EsimPeriodType = "Annually" | "Biannually" | "Semiannual" | "Quarterly" | "Monthly"

export type PhoneNumberItemType = {
  country_code: string;
  qty: number;
  period: number;
}

export type EsimHistoryItemType = {
  date_time: string;
  type: number;
  success: boolean;
  transfer_id: number;
  data_package: string | null;
  phone_number: PhoneNumberItemType;
  top_up: string | null;
}

export type ListNumbersBodyTypeResponse = {
  id: number;
  date_time: string;
  customer: UserInfo;
  country: string;
  period: EsimPeriodType;
  expiry: string;
  number: string;
  number_info: NumberInfoType;
  number_status: "Enabled" | "Disabled";
  number_assigned: string;
  call_forwarding: string;
  history: EsimHistoryItemType[]
}

export type ListNumbersResponse = ListNumbersBodyTypeResponse[]

export type NewNumberRequest = {
  customer_id: number;
  country_code: string;
  period: number;
}

type SendCodeRequest = {
  id: number;
}

export type TopUpRequest = {
  id: number;
  amount: number;
  otp_code: string;
}

type UnassignNumberRequest = {
  id: number
}

type VerifyCodeRequest = {
  id: number;
  code: string;
}

//////////

const assignNumberRestActions = createRestActions<
  PositiveResponse,
  AssignNumberRequest
>(AssignNumberRestActions);

const changeCallForwardingRestActions = createRestActions<
  PositiveResponse,
  ChangeCallForwardingRequest
>(ChangeCallForwardingRestActions);

const changeRenewStatusRestActions = createRestActions<
  PositiveResponse,
  ChangeRenewStatusRequest
>(ChangeRenewStatusRestActions);

const getSmsRestActions = createRestActions<
  GetSmsResponse,
  GetSmsRequest
>(GetSmsRestActions);

const listCountriesRestActions = createRestActions<
  ListCountriesResponse
>(ListCountriesRestActions);

const listNumbersRestActions = createRestActions<
  ListNumbersResponse,
  ListNumbersRequest
>(ListNumbersRestActions);

const newNumberRestActions = createRestActions<
  PositiveResponse,
  NewNumberRequest
>(NewNumberRestActions);

const sendCodeRestActions = createRestActions<
  PositiveResponse,
  SendCodeRequest
>(SendCodeRestActions);

const topUpRestActions = createRestActions<
  PositiveResponse,
  TopUpRequest
>(TopUpRestActions);

const unassignNumberRestActions = createRestActions<
  PositiveResponse,
  UnassignNumberRequest
>(UnassignNumberRestActions);

const verifyCodeRestActions = createRestActions<
  PositiveResponse,
  VerifyCodeRequest
>(VerifyCodeRestActions);

const EsimRestActions = {
  assignNumber: assignNumberRestActions,
  changeCallForwarding: changeCallForwardingRestActions,
  changeRenewStatus: changeRenewStatusRestActions,
  getSms: getSmsRestActions,
  listCountry: listCountriesRestActions,
  listNumbers: listNumbersRestActions,
  newNumber: newNumberRestActions,
  sendCode: sendCodeRestActions,
  topUp: topUpRestActions,
  unassignNumber: unassignNumberRestActions,
  verifyCode: verifyCodeRestActions
};

const EsimActions = {
  ...EsimRestActions,
};

type EsimRestNodes = keyof typeof EsimRestActions;

const initialRestState = {
  assignNumber: getDefaultRestState<PositiveResponse>(),
  changeCallForwarding: getDefaultRestState<PositiveResponse>(),
  changeRenewStatus: getDefaultRestState<PositiveResponse>(),
  getSms: getDefaultRestState<GetSmsResponse>(),
  listCountry: getDefaultRestState<ListCountriesResponse>(),
  listNumbers: getDefaultRestState<ListNumbersResponse>(),
  newNumber: getDefaultRestState<PositiveResponse>(),
  sendCode: getDefaultRestState<PositiveResponse>(),
  topUp: getDefaultRestState<PositiveResponse>(),
  unassignNumber: getDefaultRestState<PositiveResponse>(),
  verifyCode: getDefaultRestState<PositiveResponse>(),
};

type EsimState = NodeRestStateType<
EsimRestNodes, 
 typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<EsimState>;

const esimReducer = createReducer(
  {...initialRestState}, 
  builder =>
    (fp.flow(addAllRestReducers<typeof EsimRestActions>(EsimRestActions))(builder) as Builder)
);

export {esimReducer, EsimActions};