import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import _ from "lodash";
import {
  call,
  put,
  select,
  takeLatest,
  delay,
  race,
  take,
  all,
} from "redux-saga/effects";
import {
  api,
  RequestError,
  RequestErrorFromResponse,
  updateAuthorizationHeader
} from "../../api";
import {
  ERROR_MESSAGE_2FA_REQUIRED,
  UserActions,
  UserInfo,
} from "../../logic/user/UserRedux";
import { takeEveryRegexp, takeLeading } from "../../store/sagaHelper";
import {
  ChangePasswordResponse,
  ConfirmNewEmailResponse,
  DisableAuthenticatorCodeResponse,
  EditProfileRequest,
  EditProfileResponse,
  GetAuthenticatorCodeResponse,
  IntercomUserHashResponse,
  LoginResponse,
  LogoutResponse,
  RegisterEmailResponse,
  RegisterResponse,
  RequestResetPasswordResponse,
  ResetPasswordResponse,
  SetNotificationsResponse,
  VerifyAuthenticatorCodeResponse,
  WhoAmIResponse,
} from "../../types/api";
import {
  userIdSelector,
  userSelector,
  userTokenSelector,
  whoAmISelector,
} from "./UserSelectors";
import {
  deleteUserTokenFromLocalStorage,
  deleteTotalBalanceSnapshotFromLocalStorage,
  saveUserTokenToLocalStorage,
  storeUserIdToLocalStorage,
  deleteCurrencyListToOpenFromLocalStorage,
  deleteUserIdFromLocalStorage
} from "../../helpers/localStorage";
import { showToast } from "../../helpers/alertService";
import { GeneralActions, UploadResponseItem } from "../general/GeneralRedux";
import { StorieActions } from "../storie/StorieRedux";
import { CryptoActions } from "../crypto/CryptoRedux";
import { CONFIG } from "../../config";
import i18next from "i18next";
import { CardsActions } from "../cards/CardsRedux";
import { SocialActions } from "../social/SocialRedux";
import { coingeckoMarketChartsSelector } from "../crypto/CryptoSelectors";
import { generalSelector } from "../general/GeneralSelectors";
import { CodeBud } from "@appklaar/codebud";
import { classicApiResponseValidator, retryApiCall } from "../../helpers/sagaHelpers";
import { NftActions } from "../nft/NftRedux";

function* initialRequest(action: Action) {
  if (UserActions.runInitialSaga.match(action)) {
    try {
      // @ts-ignore
      window.Intercom('boot', {app_id: CONFIG.INTERCOM.APP_ID });

      // Get general data from server
      yield put(GeneralActions.listCountries.request());
      yield put(GeneralActions.faq.request({ route: "faq", agent_id: CONFIG.AGENT_ID }));
      yield put(GeneralActions.about.request({ route: "about-us", agent_id: CONFIG.AGENT_ID }));
      yield put(GeneralActions.contacts.request({ route: "contact", agent_id: CONFIG.AGENT_ID }));
      yield put(GeneralActions.commissions.request({view: true}));
    } catch (error) {
      console.log("User initial saga error:", error);
    }
  }
}

function* tokenRelevanceCheck(action: { type: string; error: RequestError | RequestErrorFromResponse }) {
  try {
    let { error } = action;

    if (error?.code === 401) {
      // 401 means 'not authorized'
      CodeBud.captureEvent("User token expired", {tokenRelevanceCheckError: error});
      yield put(UserActions.logout.request({}));
    }
  } catch (error) {
    yield put(UserActions.logout.request({}));
  }
}

function* logoutRequest(action: Action) {
  if (UserActions.logout.request.match(action)) {
    try {
      // console.log("LogoutSaga...");
      // Clear user data passed to intercom
      // @ts-ignore
      window.Intercom('shutdown');

      // Clear localstorage
      yield call(deleteUserTokenFromLocalStorage);
      yield call(deleteUserIdFromLocalStorage);
      yield call(deleteTotalBalanceSnapshotFromLocalStorage);
      yield call(deleteCurrencyListToOpenFromLocalStorage);

      // Clear cards redux
      yield put(CardsActions.clearAccountTransactions());
      yield put(CardsActions.clearCardTransactions());
      yield put(CardsActions.clearCardsLabelsTable());
      // Clear crypto redux
      yield put(CryptoActions.clearAccountsList());
      yield put(CryptoActions.setSwapReceiveTable({}));
      yield put(CryptoActions.setPaymentsPageSelectedAccount(null));
      // Clear general redux
      yield put(GeneralActions.clearCurrencyListToOpen());
       // Clear NFT redux
       yield put(NftActions.setNftProfile(undefined));
       yield put(NftActions.setMyNftCollections([]));
      // Clear user redux
      yield put(UserActions.storeTokenToRedux(undefined));
      yield call(updateAuthorizationHeader, "");
      yield put(UserActions.storeUserIdToRedux(undefined));
      yield put(UserActions.setUserPasscode(undefined));
      yield put(UserActions.setUserEmail(undefined));
      yield put(UserActions.setEnabled2FA(false));
      yield put(UserActions.clearWhoAmI());

      yield delay(50);
      // Init intercom without user data
      // @ts-ignore
      window.Intercom('boot', {app_id: CONFIG.INTERCOM.APP_ID });

      yield call(api.logoutGet);
    } catch (error) {
      yield put(UserActions.logout.failure(error));
    }
  }
}

function* registerEmailRequest(action: Action) {
  if (UserActions.registerEmail.request.match(action)) {
    try {
      const registerEmailResponse: ApiResponse<RegisterEmailResponse> =
        yield call(api.registerEmailPost, action.payload);
      if (registerEmailResponse.ok && registerEmailResponse.data?.success) {
        const responseData = registerEmailResponse.data;

        yield put(UserActions.setUserEmail(action.payload.email));

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield delay(200);

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(UserActions.registerEmail.success(responseData));
      } else {
        yield call(showToast, {
          title: i18next.t("serverError"),
          info: registerEmailResponse?.data?.message ?? "",
          type: "error",
        });

        throw new RequestErrorFromResponse(registerEmailResponse);
      }
    } catch (error) {
      yield put(UserActions.registerEmail.failure(error));
    }
  }
}

function* confirmNewEmailRequest(action: Action) {
  if (UserActions.confirmNewEmail.request.match(action)) {
    try {
      const confirmNewEmailResponse: ApiResponse<ConfirmNewEmailResponse> =
        yield call(api.confirmNewEmailPost, action.payload);
      if (confirmNewEmailResponse.ok && confirmNewEmailResponse.data?.success) {
        const responseData = confirmNewEmailResponse.data;

        if (action.payload.successCallback) {
          action.payload.successCallback();
        }

        yield put(UserActions.confirmNewEmail.success(responseData));
      } else {
        if (action.payload.wrongCodeCallback) {
          action.payload.wrongCodeCallback();
        }

        throw new RequestErrorFromResponse(confirmNewEmailResponse);
      }
    } catch (error) {
      yield put(UserActions.confirmNewEmail.failure(error));
    }
  }
}

function* registerRequest(action: Action) {
  if (UserActions.register.request.match(action)) {
    try {
      if (!action.payload.referral_code?.length)
        action.payload.referral_code = undefined;

      const registerResponse: ApiResponse<RegisterResponse> = yield call(
        api.registerPost,
        action.payload
      );
      if (registerResponse.ok && registerResponse.data?.success) {
        const responseData = registerResponse.data;

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield delay(200);

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(UserActions.register.success(responseData));
      } else {
        throw new RequestErrorFromResponse(registerResponse);
      }
    } catch (error) {
      yield put(UserActions.register.failure(error));
    }
  }
}

function* loginRequest(action: Action) {
  if (UserActions.login.request.match(action)) {
    try {
      const loginResponse: ApiResponse<LoginResponse> = yield call(
        api.loginPost,
        action.payload
      );
      if (loginResponse.ok && loginResponse.data) {
        const responseData = loginResponse.data;

        yield call(saveUserTokenToLocalStorage, responseData.access_token);
        yield put(UserActions.storeTokenToRedux(responseData.access_token));
        yield call(updateAuthorizationHeader, responseData.access_token);
        yield put(UserActions.setUserEmail(action.payload.email));

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        CodeBud.captureEvent("User got new token", {token: responseData.access_token});

        yield put(UserActions.login.success(responseData));
      } else {
        if (
          !action.payload.otp &&
          loginResponse.data?.message === ERROR_MESSAGE_2FA_REQUIRED
        ) {
          yield put(UserActions.setEnabled2FA(true));

          yield call(showToast, {
            title: i18next.t("securityCheck"),
            info: i18next.t("pleaseEnter2FA"),
            type: "success",
          });
        } else {
          yield call(showToast, {
            title: i18next.t("oops") as string,
            info:
              loginResponse.data?.message ??
              (i18next.t("serverError") as string),
            type: "error",
          });
        }

        throw new RequestErrorFromResponse(loginResponse);
      }
    } catch (error) {
      yield put(UserActions.login.failure(error));
    }
  }
}

function* fetchAdditionalInfo(userId: number) {
  yield all([
    put(SocialActions.news.request()),
    put(SocialActions.blog.request()),
    put(CryptoActions.prepareRequiredCryptoThings()),
    put(GeneralActions.currencyList.request({})),
    put(GeneralActions.currencyListToOpen.request({ customer_id: userId })),
    put(CryptoActions.getAccountsList.request({ total_currency: CONFIG.ZED_API_TOTAL_CURRENCY })),
    put(StorieActions.getStorieList.request({ index: 0 })),
    put(GeneralActions.getBankAccounts.request()),
    put(GeneralActions.listDocuments.request()),
    put(SocialActions.ticketsList.request({
      items: 20,
      user_id: userId,
      id: 1,
    })),
    put(SocialActions.ticketsTypes.request())
  ]);
}

function* handleMarketData() {
  const coingeckoMarketCharts: ReturnType<typeof coingeckoMarketChartsSelector> = yield select(coingeckoMarketChartsSelector);
  if (!coingeckoMarketCharts.data) {
    yield put(CryptoActions.coingeckoMarketCharts.request());
  }
}

function* whoAmIRequest(action: Action) {
  if (UserActions.whoAmI.request.match(action)) {
    try {
      const whoAmIResponse: ApiResponse<WhoAmIResponse> = yield call(retryApiCall, {
        apiRequest: api.whoAmIGet,
      });

      if (classicApiResponseValidator(whoAmIResponse)) {
        const responseData = whoAmIResponse.data!;
        const userId = responseData.user.user_id;

        yield all([
          put(UserActions.setEnabled2FA(responseData.user.otp_enabled)),
          put(UserActions.storeUserIdToRedux(userId)),
          call(storeUserIdToLocalStorage, userId + '')
        ]);

        if (action.payload?.fetchAdditionalInfoOnSuccess) {
          yield all([
            call(fetchAdditionalInfo, userId),
            call(handleMarketData),
          ]);
        }

        yield put(UserActions.whoAmI.success(responseData.user));

      } else {
        throw new RequestErrorFromResponse(whoAmIResponse);
      }
    } catch (error) {
      yield put(UserActions.whoAmI.failure(error));
    }
  }
}
function* changePasswordRequest(action: Action) {
  if (UserActions.changePassword.request.match(action)) {
    try {
      const changePasswordResponse: ApiResponse<ChangePasswordResponse> =
        yield call(api.changePasswordPost, action.payload);

      if (changePasswordResponse.ok && changePasswordResponse.data?.success) {
        const responseData = changePasswordResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield delay(100);

        if (action.payload.delete_tokens) {
          yield put(UserActions.logout.request({}));
        }

        yield put(UserActions.changePassword.success(responseData));
      } else {
        throw new RequestErrorFromResponse(changePasswordResponse);
      }
    } catch (error) {
      yield put(UserActions.changePassword.failure(error));
    }
  }
}

function* requestResetPasswordRequest(action: Action) {
  if (UserActions.requestResetPassword.request.match(action)) {
    try {
      const requestResetPasswordResponse: ApiResponse<RequestResetPasswordResponse> =
        yield call(api.requestResetPasswordPost, action.payload);
      if (
        requestResetPasswordResponse.ok &&
        requestResetPasswordResponse.data?.success
      ) {
        const responseData = requestResetPasswordResponse.data;

        yield put(UserActions.setUserEmail(action.payload.email));

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield delay(200);

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(UserActions.requestResetPassword.success(responseData));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
            requestResetPasswordResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });

        throw new RequestErrorFromResponse(requestResetPasswordResponse);
      }
    } catch (error) {
      yield put(UserActions.requestResetPassword.failure(error));
    }
  }
}

function* resetPasswordRequest(action: Action) {
  if (UserActions.resetPassword.request.match(action)) {
    try {
      const state: ReturnType<typeof userSelector> = yield select(userSelector);
      const userEmail = state.userEmail as string;

      const resetPasswordResponse: ApiResponse<ResetPasswordResponse> =
        yield call(api.resetPasswordPost, {
          ...action.payload,
          email: userEmail,
          delete_tokens: true,
        });
      if (resetPasswordResponse.ok && resetPasswordResponse.data?.success) {
        const responseData = resetPasswordResponse.data;

        // navDispatch(
        //   CommonActions.reset({
        //     index: 1,
        //     routes: [
        //       { name: 'OnboardingScreen' },
        //       { name: 'LoginScreen' },
        //     ],
        //   })
        // )

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield delay(200);

        yield call(showToast, {
          title: i18next.t("success"),
          info: responseData.message,
          type: "success",
        });

        yield put(UserActions.resetPassword.success(responseData));
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
            resetPasswordResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });

        throw new RequestErrorFromResponse(resetPasswordResponse);
      }
    } catch (error) {
      yield put(UserActions.resetPassword.failure(error));
    }
  }
}

function* getAuthenticatorCodeRequest(action: Action) {
  if (UserActions.getAuthenticatorCode.request.match(action)) {
    try {
      const getAuthenticatorCodeResponse: ApiResponse<GetAuthenticatorCodeResponse> =
        yield call(api.getAuthenticatorCodeGet);
      if (
        getAuthenticatorCodeResponse.ok &&
        getAuthenticatorCodeResponse.data?.success
      ) {
        const responseData = getAuthenticatorCodeResponse.data;
        yield put(UserActions.getAuthenticatorCode.success(responseData));
      } else {
        throw new RequestErrorFromResponse(getAuthenticatorCodeResponse);
      }
    } catch (error) {
      yield put(UserActions.getAuthenticatorCode.failure(error));
    }
  }
}

function* verifyAuthenticatorCodeRequest(action: Action) {
  if (UserActions.verifyAuthenticatorCode.request.match(action)) {
    try {
      const verifyAuthenticatorCodeResponse: ApiResponse<VerifyAuthenticatorCodeResponse> =
        yield call(api.verifyAuthenticatorCodePost, action.payload);
      if (
        verifyAuthenticatorCodeResponse.ok &&
        verifyAuthenticatorCodeResponse.data
      ) {
        const responseData = verifyAuthenticatorCodeResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: i18next.t("twoStepAuth.enabledSuccess"),
          type: "success",
        });

        yield put(UserActions.setEnabled2FA(true));
        yield put(UserActions.whoAmI.success(responseData.user));
        yield put(
          UserActions.verifyAuthenticatorCode.success(responseData.user)
        );
        // yield call(pop)
      } else {
        yield call(showToast, {
          title: i18next.t("serverError"),
          info: verifyAuthenticatorCodeResponse?.data?.message ?? "",
          type: "error",
        });

        throw new RequestErrorFromResponse(verifyAuthenticatorCodeResponse);
      }
    } catch (error) {
      yield put(
        UserActions.verifyAuthenticatorCode.failure(error)
      );
    }
  }
}

function* disableAuthenticatorCodeRequest(action: Action) {
  if (UserActions.disableAuthenticatorCode.request.match(action)) {
    try {
      const disableAuthenticatorCodeResponse: ApiResponse<DisableAuthenticatorCodeResponse> =
        yield call(api.disableAuthenticatorCodePost, action.payload);
      if (
        disableAuthenticatorCodeResponse.ok &&
        disableAuthenticatorCodeResponse.data
      ) {
        const responseData = disableAuthenticatorCodeResponse.data;

        yield call(showToast, {
          title: i18next.t("success"),
          info: i18next.t("twoStepAuth.disabledSuccess"),
          type: "success",
        });

        yield put(UserActions.setEnabled2FA(false));
        yield put(UserActions.whoAmI.success(responseData.user));
        yield put(
          UserActions.disableAuthenticatorCode.success(responseData.user)
        );
      } else {
        yield call(showToast, {
          title: i18next.t("serverError"),
          info: disableAuthenticatorCodeResponse?.data?.message ?? "",
          type: "error",
        });

        throw new RequestErrorFromResponse(disableAuthenticatorCodeResponse);
      }
    } catch (error) {
      yield put(
        UserActions.disableAuthenticatorCode.failure(error)
      );
    }
  }
}

function* sendDocumentsRequest(action: Action) {
  if (UserActions.sendDocuments.request.match(action)) {
    try {
      const sendDocumentsResponse: ApiResponse<any> = yield call(
        api.sendDocumentsPost,
        action.payload
      );
      if (sendDocumentsResponse.ok && sendDocumentsResponse.data) {
        const responseData = sendDocumentsResponse.data;
        yield put(UserActions.sendDocuments.success(responseData));
      } else {
        throw new RequestErrorFromResponse(sendDocumentsResponse);
      }
    } catch (error) {
      yield put(UserActions.sendDocuments.failure(error));
    }
  }
}

function* verifyPhoneRequest(action: Action) {
  if (UserActions.verifyPhone.request.match(action)) {
    try {
      const id: ReturnType<typeof userSelector> = yield select(userIdSelector);

      const verifyPhoneResponse: ApiResponse<any> = yield call(
        api.verifyPhone,
        id as any
      );
      if (verifyPhoneResponse.ok && verifyPhoneResponse.data) {
        yield put(UserActions.verifyPhone.success());
      } else {
        throw new RequestErrorFromResponse(verifyPhoneResponse);
      }
    } catch (error) {
      yield put(UserActions.verifyPhone.failure(error));
    }
  }
}

function* confirmPhoneRequest(action: Action) {
  if (UserActions.confirmPhone.request.match(action)) {
    try {
      const confirmPhoneResponse: ApiResponse<any> = yield call(
        api.confirmPhone,
        action.payload
      );
      if (confirmPhoneResponse.ok && confirmPhoneResponse.data) {
        const responseData = confirmPhoneResponse.data;
        yield put(UserActions.confirmPhone.success(responseData));
      } else {
        throw new RequestErrorFromResponse(confirmPhoneResponse);
      }
    } catch (error) {
      yield put(UserActions.confirmPhone.failure(error));
    }
  }
}

function* changeAvatarRequest(action: Action) {
  if (UserActions.changeAvatar.request.match(action)) {
    try {
      var filesArray: string[] = [];
      if (action.payload.avatar instanceof FormData) {
        yield put(GeneralActions.uploadFile.request(action.payload.avatar));
        const { success } = yield race({
          success: take(GeneralActions.uploadFile.success),
          failure: take(GeneralActions.uploadFile.failure),
        });
        if (success) {
          const {
            uploadFile: { data: uploadData },
          } = yield select(generalSelector);
          filesArray = uploadData.value.map((item: UploadResponseItem) => {
            return item.Value;
          });
        } else throw new Error("File was not uploaded");
      }

      let param: { id: number; avatar: string } = {
        ...action.payload,
        avatar: filesArray[0],
      };
      const changeAvatarResponse: ApiResponse<any> = yield call(
        api.changeAvatar,
        param
      );
      if (changeAvatarResponse.ok && changeAvatarResponse.data) {
        const responseData = changeAvatarResponse.data;
        yield put(UserActions.changeAvatar.success(responseData as any));
        yield put(UserActions.whoAmI.request());
      } else {
        throw new RequestErrorFromResponse(changeAvatarResponse);
      }
    } catch (error) {
      yield put(UserActions.changeAvatar.failure(error));
    }
  }
}

function* editProfileRequest(action: Action) {
  if (UserActions.editProfile.request.match(action)) {
    try {
      const editProfileResponse: ApiResponse<EditProfileResponse> = yield call(
        api.editProfile,
        action.payload.params
      );
      if (editProfileResponse.ok && editProfileResponse.data) {
        yield put(UserActions.whoAmI.success(editProfileResponse.data.user));

        yield call(showToast, {
          title: i18next.t("success"),
          info:
            editProfileResponse?.data?.message ??
            (i18next.t("editProfilePage.successInfo") as string),
          type: "success",
        });

        if (action.payload.verifyPhone) {
          yield put(UserActions.verifyPhone.request());
        }

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield put(UserActions.editProfile.success());
      } else {
        yield call(showToast, {
          title: i18next.t("oops") as string,
          info:
            editProfileResponse.data?.message ??
            (i18next.t("serverError") as string),
          type: "error",
        });

        throw new RequestErrorFromResponse(editProfileResponse);
      }
    } catch (error) {
      yield put(UserActions.editProfile.failure(error));
    }
  }
}

function* setNotificationsRequest(action: Action) {
  if (UserActions.setNotifications.request.match(action)) {
    try {
      const setNotificationsResponse: ApiResponse<SetNotificationsResponse> = yield call(
        api.setNotificationsPost,
        action.payload
      );
      if (setNotificationsResponse.ok && setNotificationsResponse.data) {
        const responseData = setNotificationsResponse.data;

        yield put(UserActions.whoAmI.success(responseData.user));

        yield put(UserActions.setNotifications.success());
      } else {
        if (action.payload.manual) {
          yield call(showToast, {
            title: i18next.t('oops') as string,
            info: setNotificationsResponse.data?.message ?? i18next.t('serverError') as string,
            type: "error",
          });
        }

        throw new RequestErrorFromResponse(setNotificationsResponse);
      }
    } catch (error) {
      yield put(UserActions.setNotifications.failure(error));
    }
  }
}

function* intercomUserHashRequest(action: Action) {
  if (UserActions.intercomUserHash.request.match(action)) {
    try {
      const intercomUserHashResponse: ApiResponse<IntercomUserHashResponse> = yield call(
        api.intercomUserHashGet,
        {
          platform: "web"
        }
      );
      if (intercomUserHashResponse.ok && intercomUserHashResponse.data) {
        const responseData = intercomUserHashResponse.data;

        // @ts-ignore
        window.Intercom('boot', {
          app_id: CONFIG.INTERCOM.APP_ID,
          email: action.payload.profile.email,
          user_id: action.payload.profile.user_id.toString(),
          user_hash: responseData.userHash
        });
        
        yield put(UserActions.intercomUserHash.success(responseData.userHash));
      } else {
        throw new RequestErrorFromResponse(intercomUserHashResponse);
      }
    } catch (error) {
      yield put(UserActions.intercomUserHash.failure(error));
    }
  }
}

export function* UserSaga() {
  yield* [
    takeLeading(UserActions.runInitialSaga.type, initialRequest),
    takeLatest(UserActions.registerEmail.request.type, registerEmailRequest),
    takeLatest(
      UserActions.confirmNewEmail.request.type,
      confirmNewEmailRequest
    ),
    takeLatest(UserActions.register.request.type, registerRequest),
    takeEveryRegexp(/_failure$/, tokenRelevanceCheck),
    takeLatest(UserActions.logout.request.type, logoutRequest),
    takeLatest(UserActions.login.request.type, loginRequest),
    takeLatest(UserActions.whoAmI.request.type, whoAmIRequest),
    takeLatest(UserActions.changePassword.request.type, changePasswordRequest),
    takeLatest(
      UserActions.requestResetPassword.request.type,
      requestResetPasswordRequest
    ),
    takeLatest(UserActions.changeAvatar.request.type, changeAvatarRequest),
    takeLatest(UserActions.resetPassword.request.type, resetPasswordRequest),
    takeLatest(
      UserActions.getAuthenticatorCode.request.type,
      getAuthenticatorCodeRequest
    ),
    takeLatest(
      UserActions.verifyAuthenticatorCode.request.type,
      verifyAuthenticatorCodeRequest
    ),
    takeLatest(UserActions.sendDocuments.request.type, sendDocumentsRequest),
    takeLatest(
      UserActions.disableAuthenticatorCode.request.type,
      disableAuthenticatorCodeRequest
    ),
    takeLatest(UserActions.verifyPhone.request.type, verifyPhoneRequest),
    takeLatest(UserActions.confirmPhone.request.type, confirmPhoneRequest),
    takeLatest(UserActions.editProfile.request.type, editProfileRequest),
    takeLeading(UserActions.setNotifications.request.type, setNotificationsRequest),
    takeLeading(UserActions.intercomUserHash.request.type, intercomUserHashRequest)
  ];
}
