export const stringifyPrettySingleLine = (s: any) =>
  JSON.stringify(s).replace(/\,/g, ", ").replace(/\:/g, ": ");

export const foundСoincidences = (searchIn: string, searchFor: string) =>
  searchIn.toLowerCase().includes(searchFor.toLowerCase());

export const getRefferalId = (id: number) => "ZP21500000" + id;

export const stringWithMaxLen = (s: string, max: number) => s.length > max ? `${s.slice(0, max)}...` : s;

export const addLeadZeroesTimer = (n: number) => {
  const s = n.toString();
  return (s.length === 1 ? `0${s}` : s);
}

export const getTagsFromStringSeparatedWithCommas = (s: string) => {
  const tagsCleared = s.split(",").map((t) => t.replace(/,/g, '').trim()).filter((t) => t.length > 0);
  const tagsSet = new Set(tagsCleared);
  const tagsNoRepetitive = Array.from(tagsSet);

  return tagsNoRepetitive.map((t) => ({tag: t}));
}

export function extractTxId(jsonString:string) {
  try {
      const parsedData = JSON.parse(jsonString);
      
      return parsedData.txId;
  } catch (error) {
      console.error("Error extractTxId", error);
      return null;
  }
}