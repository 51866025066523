import { create } from "apisauce";
import * as T from './../types/api';
import { CONFIG } from "./../config";
import { addAxiosResponseInterceptor } from "./handleRedirect";
import {
  ListDocumentsResponse,
  UploadFileResponse,
  WhitelabelDataPayload,
} from "../logic/general/GeneralRedux";
import {
  SendDocumentsPayload,
  ZedAPIDefaultResponse,
} from "../logic/user/UserRedux";

const PAGINATION_CONFIG = {
  // Number of results to return per page
  limit: 15,
};

const DEFAULT_HEADERS = {
  "x-before-transform-request": "true"
};

const sauce = create({
  baseURL: CONFIG.BASE_URL,
  headers: { 
    Accept: "application/json",
    ...DEFAULT_HEADERS
  },
});
addAxiosResponseInterceptor(sauce);

// Is not authorized by default. Token can be set with updateAuthorizationHeader
const sauceAuthorized = create({
  baseURL: CONFIG.BASE_URL,
  headers: { 
    Accept: "application/json",
    ...DEFAULT_HEADERS
  },
});
addAxiosResponseInterceptor(sauceAuthorized);

const createSauceForUploadPhoto = (token: string) => {
  const sauceUploadPhotoAuthorized = create({
    baseURL: CONFIG.BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      ...DEFAULT_HEADERS
    },
  });
  addAxiosResponseInterceptor(sauceUploadPhotoAuthorized);

  return sauceUploadPhotoAuthorized;
};

// Updates sauceAuthorized instance (puts new token into headers)
const updateAuthorizationHeader = (token: string) => {
  sauceAuthorized.setHeader("Authorization", `Bearer ${token}`);
};

const api = {
  registerEmailPost: (params: T.RegisterEmailRequest) => sauce.post<T.RegisterEmailResponse>(`/user/register_email`, params),
  confirmNewEmailPost: (params: T.ConfirmNewEmailRequest) => sauce.post<T.ConfirmNewEmailResponse>(`/user/confirm_new_email`, params),
  listCountriesGet: () => sauce.get<T.ListCountriesResponse>(`/general/list_countries`),
  registerPost: (params: T.RegisterRequest) => sauce.post<T.RegisterResponse>(`/user/register`, params),
  loginPost: (params: T.LoginRequest) => sauce.post<T.LoginResponse>(`/user/login`, params),
  whoAmIGet: () => sauceAuthorized.get<T.WhoAmIResponse>(`/user/who_am_i`),
  logoutGet: () => sauceAuthorized.get<T.LogoutResponse>(`/user/close_session`),
  changePasswordPost: (params: T.ChangePasswordRequest) => sauceAuthorized.post<T.ChangePasswordResponse>(`/user/change_password`, params),
  requestResetPasswordPost: (params: T.RequestResetPasswordRequest) => sauce.post<T.RequestResetPasswordResponse>(`/user/request_reset_password`, params),
  resetPasswordPost: (params: T.ResetPasswordRequest) => sauce.post<T.ResetPasswordResponse>(`/user/reset_password`, params),
  getAuthenticatorCodeGet: () => sauceAuthorized.get<T.GetAuthenticatorCodeResponse>(`/user/get_authenticator_code`),
  verifyAuthenticatorCodePost: (params: T.VerifyAuthenticatorCodeRequest) => sauceAuthorized.post<T.VerifyAuthenticatorCodeResponse>(`/user/verify_authenticator_code`, params),
  disableAuthenticatorCodePost: (params: T.DisableAuthenticatorCodeRequest) => sauceAuthorized.post<T.DisableAuthenticatorCodeResponse>(`/user/disable_authenticator_code`, params),
  listDocumentsGet: () => sauceAuthorized.get<ListDocumentsResponse>(`/general/list_document_types`),
  newsGet: () => sauceAuthorized.get<T.NewsResponse>(`/news/get_all`,{agent_id:CONFIG.AGENT_ID}),
  blogGet: () => sauce.get<T.BlogResponse>(`/blog/list`,{agent_id: CONFIG.AGENT_ID}),
  adGet: () => sauceAuthorized.get<T.AdResponse>(`/ad/list/0`),
  getStorieByIdGet: (params: T.GetStorieByIdRequest) => sauceAuthorized.get<T.GetStorieByIdResponse>(`/storie/one/${params.storieId}`),
  getStorieListGet: () => sauceAuthorized.get<T.GetStorieListResponse>(`/storie/get_all`,{agent_id:CONFIG.AGENT_ID}),
  getAccountsListGet: (params: T.GetAccountsListRequest) => sauceAuthorized.get<T.GetAccountsListResponse>(`/account/list`, params),
  openAccountPost: (params: T.OpenAccountRequest) => sauceAuthorized.post<T.OpenAccountResponse>(`/account/open`, params),
  currencyListToOpenGet: (params: T.CurrencyListToOpenRequest) => sauceAuthorized.get<T.CurrencyListToOpenResponse>(`/currency/list_to_open`, params),
  uploadFile: (params: any, token: string) => {
    let sauce = createSauceForUploadPhoto(token);
    return sauce.post<UploadFileResponse>(`/general/upload_file`, params);
  },
  sendDocumentsPost: (params: SendDocumentsPayload) => sauceAuthorized.post<any>(`/user/submit_documents`, params),
  withdrawTypesGet: () => sauceAuthorized.get<T.WithdrawTypesResponse>(`/static/get_withdraw_types`),
  getMyFCFCards: (params: T.GetMyCardsRequest) => sauceAuthorized.get<T.GetMyCardsResponse>(`/card/list`, {}),
  getMyAlchemaCards: (params: T.GetMyCardsRequest) => sauceAuthorized.get<T.GetMyCardsResponse>(`/apcard/list`, params),
  createVirtualCard: (params: T.CreateVirtualCardRequest) => sauceAuthorized.post<T.CreateVirtualCardResponse>(`/account/new_virtual_card`, params),
  createVisaCard: (params: T.CreateVisaCardRequest) => sauceAuthorized.post<T.CreateVisaCardResponse>(`/account/new_visa_card`, params),
  depositPost: (params: T.DepositRequest) => sauceAuthorized.post<T.DepositResponse>(`/account/deposit`, params),
  buyCryptoElementsGet: (params: T.BuyCryptoElementsRequest) => sauceAuthorized.get<T.BuyCryptoElementsResponse>(`/account/buy_crypto_elements`, params),
  generateQRCodeGet: (params: T.GenerateQRCodeRequest) => sauceAuthorized.get<T.GenerateQRCodeResponse>(`/general/generate_qr_code`, {...params, agent: CONFIG.AGENT_ID}),
  getWhitelabelData: (params: WhitelabelDataPayload) => sauceAuthorized.get<T.WhitelabelDataResponse>(`/white_label/get_page`,params),
  deleteAccountPost: (params: T.DeleteAccountRequest) => sauceAuthorized.post<T.DeleteAccountResponse>(`/account/delete`, params),
  changeAccountStatusPost: (params: T.ChangeAccountStatusRequest) => sauceAuthorized.post<T.ChangeAccountStatusResponse>(`/account/change`, params),
  transferPost: (params: T.TransferRequest) => sauceAuthorized.post<T.TransferResponse>(`/account/transfer`, params),
  verifyPhone: (id: number) => sauceAuthorized.post<any>(`/user/verify_mobile`, { id }),
  confirmPhone: (params: T.ConfirmMobileRequest) => sauceAuthorized.post<any>(`/user/confirm_mobile`, params),
  editProfile: (params: T.EditProfileItem) => sauceAuthorized.post<T.EditProfileResponse>(`/user/edit_profile`, params),
  getTransactions: (params: T.GetTransactionsRequest) => sauceAuthorized.get<T.GetTransactionsResponse>(`/account/transactions`, params),
  ticketsList: (params: T.TicketsListRequest) => sauceAuthorized.get<T.TicketsListResponse>(`/ticket/list?items=${params.items}&user_id=${params.user_id}&id=${params.id}`),
  ticketsTypes: () => sauceAuthorized.get<T.TicketsTypesResponse>(`/ticket/list_departments`),
  ticketOpen: (params: T.TicketOpenRequest) => sauceAuthorized.post<T.TicketOpenResponse>(`/ticket/open`, params),
  ticketReply: (params: T.TicketReplyRequest) => sauceAuthorized.post<T.TicketReplyResponse>(`/ticket/reply`, params),
  getBankAccountsGet: () => sauceAuthorized.get<T.GetBankAccountsResponse>(`/general/get_bank_accounts`),
  coingeckoMarketChartsGet: () => sauceAuthorized.get<T.CoingeckoMarketChartsResponse>(`/currency/table`),
  convertCurrencyPost: (params: T.ConvertCurrencyRequest) => sauceAuthorized.post<T.ConvertCurrencyResponse>(`/currency/convert`, params),
  ticketChange: (params: T.TicketChangeRequest) => sauceAuthorized.post<T.TicketOpenResponse>(`/ticket/change`, params),
  allowedCountriesGet: () => sauceAuthorized.get<T.AllowedCountriesResponse>(`/card/list_countries`),
  cardFeaturesGet: () => sauceAuthorized.get<T.CardFeaturesResponse>(`/card/features`),
  issueVirtualCardPost: (params: T.IssueVirtualCardRequest) => sauceAuthorized.post<T.IssueVirtualCardResponse>(`/apcard/issue_virtual_card`, params),
  getCVVGet: (params: T.GetCVVRequest) => sauceAuthorized.get<T.GetCVVResponse>(`/card/get_cvv`, params),
  getAlchemaCVVGet: (params: T.GetCVVRequest) => sauceAuthorized.get<T.GetCVVResponse>(`/apcard/get_cvv`, {id:params.id}),
  loadVirtualCardPost: (params: T.LoadVirtualCardRequest) => sauceAuthorized.post<T.LoadVirtualCardRespone>(`/card/load_virtual_card`, params),
  loadPhysicalCardPost: (params: T.LoadPhysicalCardRequest) => sauceAuthorized.post<T.LoadPhysicalCardResponse>(`/card/load_physical_card`, params),
  changeAvatar: (params: { id: number; avatar: string }) => sauceAuthorized.post<ZedAPIDefaultResponse>(`/user/change_avatar`, params),
  cardTransactionsGet: (params: T.CardTransactionsRequest) => sauceAuthorized.get<T.CardTransactionsResponse>(`/card/transactions`, params),
  cardToCardPost: (params: T.CardToCardRequest) => sauceAuthorized.post<T.CardToCardResponse>(`/card/card_to_card`, params),
  remitPost: (params: T.RemitRequest) => sauceAuthorized.post<T.RemitResponse>(`/account/remit`, params),
  newHolderPost: (params: T.NewHolderRequest) => {
    const query = params.virtual ? { virtual: true } : undefined;
    delete params.virtual;
    return sauceAuthorized.post<T.NewHolderResponse>(`/card/new_holder`, params, {params: query});
  },
  submitKycPost: (params: T.SubmitKycRequest) => sauceAuthorized.post<T.SubmitKycResponse>(`/card/submit_kyc`, params),
  activatePost: (params: T.ActivateRequest) => sauceAuthorized.post<T.ActivateResponse>(`/card/activate`, params),
  unloadVirtualCardPost: (params: T.UnloadVirtualCardRequest) => sauceAuthorized.post<T.UnloadVirtualCardResponse>(`/card/unload_virtual_card`, params),
  unloadPhysicalCardPost: (params: T.UnloadPhysicalCardRequest) => sauceAuthorized.post<T.UnloadPhysicalCardResponse>(`/card/unload_physical_card`, params),
  swapReceiveGet: (params: T.SwapReceiveRequest) => sauceAuthorized.get<T.SwapReceiveResponse>(`/account/get_swap_receive`, params),
  searchBanksGet: (params: T.SearchBanksRequest) => sauceAuthorized.get<T.SearchBanksResponse>(`/general/search_banks`, params),
  localBanksGet: (params: T.LocalBanksRequest) => sauceAuthorized.get<T.LocalBanksResponse>(`/general/list_countries`, {is_local_bank:true}),
  swiftCountriesGet: () => sauceAuthorized.get<T.SwiftCountriesResponse>(`/general/swift_countries`),
  setNotificationsPost: (params: T.SetNotificationsRequest) => sauceAuthorized.post<T.SetNotificationsResponse>(`/user/set_notifications`, params),
  cardCommissionsGet: () => sauceAuthorized.get<T.CardCommissionsResponse>(`/card/commissions`),
  commissionsGet: (params: T.CommissionsRequest) => sauceAuthorized.get<T.CommissionsResponse>(`/general/get_commissions`, params),
  intercomUserHashGet: (params: T.IntercomUserHashRequest) => sauceAuthorized.get<T.IntercomUserHashResponse>(`/user/get_intercom_user_hash/${params.platform}`),
  getPlansGet: () => sauceAuthorized.get<T.GetPlansResponse>('/nft/get_plans'),
  getContractCostPost: () => sauceAuthorized.post<T.GetContractPostResponse>(`/nft/get_contract_cost`),
  financialReportGet: (params: T.FinancialReportRequest) => sauceAuthorized.get<T.FinancialReportResponse>('/nft/financial_report', params),
  deleteNftProfilePost: (params: T.DeleteNftProfileRequest) => sauceAuthorized.post<T.DeleteNftProfileResponse>(`/nft/delete_profile`, params),
  editNftProfilePost: (params: T.EditNftProfileRequest) => sauceAuthorized.post<T.EditNftProfileResponse>(`/nft/edit_user`, params),
  editNftPost: (params: T.EditNftRequest) => sauceAuthorized.post<T.EditNftResponse>(`/nft/edit`, params),
  listNftUsersGet: (params: T.ListNftUsersRequest) => sauceAuthorized.get<T.ListNftUsersResponse>('/nft/list_users', params),
  listCategoriesGet: () => sauceAuthorized.get<T.ListCategoriesResponse>('/nft/list_categories'),
  listCollectionsGet: (params: T.ListCollectionsRequest) => sauceAuthorized.get<T.ListCollectionsResponse>('/nft/list_collections', params),
  changePlanPost: (params: T.ChangePlanRequest) => sauceAuthorized.post<T.ChangePlanResponse>(`/nft/change_plan`, params),
  changeLikeStatusPost: (params: T.ChangeLikeStatusRequest) => sauceAuthorized.post<T.ChangeLikeStatusResponse>(`/nft/change_like_status`, params),
  listNftsGet: (params: T.ListNftsRequest) => sauceAuthorized.get<T.ListNftsResponse>('/nft/list', params),
  changeFollowStatusPost: (params: T.ChangeFollowStatusRequest) => sauceAuthorized.post<T.ChangeFollowStatusResponse>(`/nft/change_follow_status`, params),
  createNftProfilePost: (params: T.CreateNftProfileRequest) => sauceAuthorized.post<T.CreateNftProfileResponse>(`/nft/register`, params),
  createNftCollectionPost: (params: T.CreateNftCollectionRequest) => sauceAuthorized.post<T.CreateNftCollectionResponse>(`/nft/create_collection`, params),
  editNftCollectionPost: (params: T.EditNftCollectionRequest) => sauceAuthorized.post<T.EditNftCollectionResponse>(`/nft/edit_collection`, params),
  listContractsGet: (params: T.ListContractsRequest) => sauceAuthorized.get<T.ListContractsResponse>(`/nft/list_contracts`, params),
  getMyZedAuthTokenGet: () => sauceAuthorized.get<T.GetMyZedAuthTokenResponse>(`/user/get_my_zedauth_token`),
  createNftPost: (params: T.CreateNftRequest) => sauceAuthorized.post<T.CreateNftResponse>(`/nft/create`, params),
  currencyListGet: (params: T.CurrencyListRequest) => sauceAuthorized.get<T.CurrencyListResponse>(`/currency/list`, params),
  ////////E-sim
  assignNumber: (params: T.AssignNumberPostRequest) => sauceAuthorized.post<T.AssignNumberResponse>(`/esim/assign_number`, params),
  changeCallForwarding: (params: T.ChangeCallForwardingRequest) => sauceAuthorized.post<T.ChangeCallForwardingResponse>(`/esim/change_call_forwarding`, params),
  changeRenewStatus: (params: T.ChangeRenewStatusPostRequest) => sauceAuthorized.post<T.ChangeRenewStatusResponse>(`/esim/change_renew_status`, params),
  getSms: (params: T.GetSmsRequest) => sauceAuthorized.get<T.GetSmsResponse>(`/esim/get_sms`, params),
  listCountry: () => sauceAuthorized.get<T.ListCountriesEsimResponse>(`/esim/list_countries`),
  listNumbers: (params: T.ListNumbersRequest) => sauceAuthorized.get<T.ListNumbersResponse>(`/esim/list_numbers`,params),
  newNumber: (params: T.NewNumberPostRequest) => sauceAuthorized.post<T.NewNumberResponse>(`/esim/new_number`,params),
  sendCode: (params: T.SendCodePostRequest) => sauceAuthorized.post<T.SendCodeResponse>(`/esim/send_code`,params),
  topUp: (params: T.TopUpPostRequest) => sauceAuthorized.post<T.TopUpResponse>(`/esim/top_up`,params),
  unassignNumber: (params: T.UnassignNumberRequest) => sauceAuthorized.post<T.UnassignNumberResponse>(`/esim/unassign_number`,params),
  verifyCode: (params: T.VerifyCodeRequest) => sauceAuthorized.post<T.VerifyCodeResponse>(`/esim/verify_code`,params),
  ////////Escrow
  escrowChange: (params: T.EscrowChangeRequest) => sauceAuthorized.post<T.EscrowChangeResponse>(`/escrow3/change`, params),
  escrowDelete: (params: T.EscrowDeleteRequest) => sauceAuthorized.post<T.EscrowDeleteResponse>(`/escrow3/delete`, params),
  escrowEdit: (params: T.EscrowEditRequest) => sauceAuthorized.post<T.EscrowEditResponse>(`/escrow3/edit`, params),
  escrowList: (params: T.EscrowListRequest) => sauceAuthorized.get<T.EscrowListResponse>(`/escrow3/list`, params),
  escrowNew: (params: T.EscrowNewRequest) => sauceAuthorized.post<T.EscrowNewResponse>(`/escrow3/new`, params),
}

export { api, updateAuthorizationHeader, PAGINATION_CONFIG };