import React, { memo } from 'react';
import { useStyles } from './TotalBalanceStyles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getAdaptivePriceFormatter } from '../../helpers/price';
import { useThemeContext } from '../../theme';
import cn from 'classnames';

export type TotalBalanceProps = {
  totalBalance: number;
  balanceLabelText?: string;
  currencySymbol?: string;
  flexDirection?: 'column' | 'row';
  balanceTextFontSize?: number;
};

const TotalBalance: React.FC<TotalBalanceProps> = ({
  totalBalance,
  balanceLabelText,
  currencySymbol,
  flexDirection,
  balanceTextFontSize
}) => {
  const s = useStyles();
  // @ts-ignore
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const balanceFormatted = getAdaptivePriceFormatter(totalBalance, currencySymbol).formattedPrice;

  return (
    <Box className={cn(s.container)} style={{width: flexDirection === 'row' ? '100%' : undefined}}>
      <Box className={cn(s.totalTextCont)} style={{flexDirection}}>
        <p className={cn(s.totalText)}>
          {balanceLabelText ?? t("totalBalance")}
        </p>
        <p className={cn(s.balanceText)} style={{fontSize: balanceTextFontSize, marginTop: flexDirection === "column"? theme.metrics.x3: 0}}>
          {balanceFormatted}
        </p>
      </Box>
    </Box>
  );
};

TotalBalance.defaultProps={
  currencySymbol: "$",
  flexDirection: 'column',
  balanceTextFontSize: 32
}

const MemorizedComponent = memo(TotalBalance);
export { MemorizedComponent as TotalBalance };