import { useSelector } from "react-redux";
import { Text } from "../../components/Text/Text";
import { useThemeContext } from "../../theme";
import { ImageLogo, ItemWrapper, Wrapper } from "./AboutStyles";
import { generalSelector } from "../../logic/general/GeneralSelectors";
import { useTranslation } from "react-i18next";
import { CONFIG } from "../../config";
import { parseKeyValuePairs } from "./parser";

const About = () => {
  const { theme } = useThemeContext();
  // @ts-ignore
  const { t } = useTranslation();

  const {
    about: { data: aboutData },
    contacts: { data: contactsData },
  } = useSelector(generalSelector);

  if (!aboutData || !contactsData) return null;
  
  const parsedContacts = parseKeyValuePairs(contactsData.value.content_body)

  return (
    <Wrapper>
      {/* <ImageLogo src="/images/Main_Logo.png" /> */}
      <div style={{color:theme.colors.white,...theme.fonts.f400}} dangerouslySetInnerHTML={{ __html: aboutData.value.content_body }} />
      {parsedContacts.map((item: any, index: number) => (
        <ItemWrapper key={index}>
          <Text
            color={theme.colors.white}
            size="14px"
            fontStyle={theme.fonts.f600}
          >
            {item.title}
          </Text>
          <Text
            color={theme.colors.yellowMain}
            size="14px"
            fontStyle={theme.fonts.f500}
          >
            {item.value}
          </Text>
        </ItemWrapper>
      ))}
      <ItemWrapper>
        <Text
          color={theme.colors.white}
          size="14px"
          fontStyle={theme.fonts.f600}
        >
          {t("appVersion")}
        </Text>
        <Text
          color={theme.colors.yellowMain}
          size="14px"
          fontStyle={theme.fonts.f500}
        >
          {`${CONFIG.APP_INFO.DISPLAY_VERSION} (${CONFIG.APP_INFO.DISPLAY_BUILD_NUMBER})`}
        </Text>
      </ItemWrapper>
    </Wrapper>
  );
};
export default About;
