import { Dispatch, FC, Key, SetStateAction, useState } from "react";
import { FixedContainer, TicketsArea, Wrapper } from "./SideBarStyles";
import { MessageData } from "../data";
import { Ticket } from "../Ticket";
import { Btn } from "../../../components/Button/Btn";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { SlidingPickerV2 } from "./../../../components/SlidingPickerV2";
import i18next from "i18next";
import { ReloadIcon } from "../../Layout/SideBar/SideBarStyles";
import { useTicketsList } from "../hooks/useTicketsList";
import LoaderRing from "../../../helpers/Loader/LoaderRing";

const tabsData = ["All", "Pending", "In Progress", "Completed", "Closed"];

type Props = {
  data: MessageData[];
  setId: Dispatch<SetStateAction<number>>;
  setPageStep: any;
  setModal: any;
};

const SideBar: FC<Props> = ({ data, setId, setModal, setPageStep }) => {
  const { theme } = useThemeContext();
  const [pickedPeriod, setPickedPeriod] = useState(0);
  const { t } = i18next;
  const { loading, getList, data: ticketsData } = useTicketsList();

  return (
    <Wrapper>
      <div style={{ display: "flex", columnGap: "10px" }}>
        <SlidingPickerV2
          periods={tabsData}
          pickedPeriod={pickedPeriod}
          setPickedPeriod={(i) => setPickedPeriod(i)}
        />
        <ReloadIcon
          fetching={loading}
          src="/images/reload.svg"
          onClick={() => getList()}
          alt="reload image"
        />
      </div>
      <TicketsArea>
        {loading ? (
          <div style={{ margin: "70% auto" }}>
            <LoaderRing size="50px" color={theme.colors.yellowMain} />
          </div>
        ) : ticketsData?.length ? (
          tabsData[pickedPeriod] === "All" ? (
            ticketsData.map((el: any, index: any) => (
              <Ticket
                setPageStep={setPageStep}
                data={el}
                setId={setId}
                key={index}
              />
            ))
          ) : ticketsData.some(
              (el: any) => el.status === tabsData[pickedPeriod]
            ) ? (
            <>
              {ticketsData
                .filter((el: any) => el.status === tabsData[pickedPeriod])
                .map((el: any, index: any) => (
                  <Ticket
                    setPageStep={setPageStep}
                    data={el}
                    setId={setId}
                    key={index}
                  />
                ))}
            </>
          ) : (
            <Text color={theme.colors.white} margin="70% auto" size="22px">
              {t("supportPage.noTickets")}
            </Text>
          )
        ) : (
          <Text color={theme.colors.white} margin="70% auto" size="22px">
            {t("supportPage.noTickets")}
          </Text>
        )}
      </TicketsArea>
      <FixedContainer>
        <Btn
          type="support"
          label="Create new Ticket"
          buttonActive={true}
          func={() => setModal(true)}
        />
      </FixedContainer>
    </Wrapper>
  );
};
export default SideBar;
