import { useEffect, useMemo, useState } from "react";
import { userSelector } from "../../../logic/user/UserSelectors";
import { useDispatch, useSelector } from "react-redux";
import { GridContainer } from "../../Profile/ProfileStyles";
import { Btn } from "../../../components/Button/Btn";
import { Text } from "../../../components/Text/Text";
import { theme } from "../../../theme/default/theme";
import Item from "../../Profile/components/profileItem/Item";
import i18next from "i18next";
import { generalSelector } from "../../../logic/general/GeneralSelectors";
import {
  validateEmail,
  validateName,
  validatePhone,
  validateZipCode,
  validateAddress,
  validateCity,
  validateDateYYYYMMDD,
  validateState
} from "../../../helpers/validationHelper";
import AreYouSure from "../../../components/Modals/AreYouSure/AreYouSure";
import { CardsActions } from "../../../logic/cards/CardsRedux";
import { showToast } from "../../../helpers/alertService";
import { useCountryText } from "../../../hooks/useCountryText";
import { useHideErrors } from "../../../hooks/useHideErrors";
import moment from "moment";
import { cardsSelector } from "../../../logic/cards/CardsSelectors";

const INITIAL_BALANCE_OPTIONS = [
  50, 100, 200, 300, 500, 1000
];
const DROPDOWN_DATA_INITIAL_BALANCE = INITIAL_BALANCE_OPTIONS.map(
  (balance) => ({
    label: `${balance} USD`,
    value: balance,
  })
);

const Inputs = ({ cardName, setStep, cardSetting, step }: any) => {
  const { t } = i18next;
  const dispatch = useDispatch();

  const GENDERS_DATA = useMemo(
    () => [
      // {
      //   value: "Not_specified",
      //   label: t("profile.gender.notSpecified"),
      //   key: "Not_specified",
      // },
      {
        value: "Male",
        label: t("profile.gender.male"),
      },
      {
        value: "Female",
        label: t("profile.gender.female"),
      },
      // {
      //   value: "Non_binary",
      //   label: t("profile.gender.nonBinary"),
      // },
      // {
      //   value: "Transgender",
      //   label: t("profile.gender.transgender"),
      // },
      // {
      //   value: "Intersex",
      //   label: t("profile.gender.intersex"),
      // },
    ],
    []
  );

  const GENDER_LABEL = useMemo(
    () => ({
      Not_specified: t("notSpecified"),
      Male: t("male"),
      Female: t("female"),
      Non_binary: t("nonBinary"),
      Transgender: t("transgender"),
      Intersex: t("intersex"),
    }),
    []
  );

  const {
    userId,
    whoAmI: { data: profile },
  } = useSelector(userSelector);

  const {
    issueVirtualCard: { fetching: virtualFetching },
    newHolder: {data: fcfCarRightData}
  } = useSelector(cardsSelector);

  const {
    listCountries: { data: countriesData },
  } = useSelector(generalSelector);

  const [areYouSureModalVisible, setAreYouSureModalVisible] = useState(false);

  const { hideErrors, setHideErrors, onInputErrorAnimationEnd } =
    useHideErrors();

  const [profileData, setProfileData] = useState({
    first: "",
    last: "",
    gender: GENDERS_DATA[0].value,
    phone: "",
    email: "",
    bDate: "",
    country: profile?.country?.id ?? 0,
    city: "",
    state: "",
    adress: "",
    initialB: DROPDOWN_DATA_INITIAL_BALANCE[0].value,
    zipCode: "",
    refferalCode: "",
  });

  const inputHandler = (e: { target: { name: string; value: string } }) => {
    setProfileData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const countryText = useCountryText(profile);

   //sync data with profile
   useEffect(() => {
    if(profile){
      setProfileData(
        {
          first: profile.first_name,
          last: profile.last_name,
          gender: GENDERS_DATA[0].value,
          phone: profile.mobile,
          email: profile.email,
          bDate: profile.birth_date?moment(profile.birth_date,'DD/MM/YYYY').format('YYYY-MM-DD'):'',
          country: profile?.country?.id ?? 0,
          city: profile.city??'',
          state: "",
          adress: profile.address??'',
          initialB: DROPDOWN_DATA_INITIAL_BALANCE[0].value,
          zipCode: "",
          refferalCode: "",
        }
      )
    }
  },[])

  const handlePressSubmit = () => {
    if (
      validateName(profileData.first) &&
      validateName(profileData.last) &&
      validateEmail(profileData.email) &&
      validatePhone(profileData.phone) &&
      validateAddress(profileData.adress) &&
      validateCity(profileData.city) &&
      validateState(profileData.state) &&
      validateZipCode(profileData.zipCode) &&
      (cardSetting.type === "Virtual" ||
        validateDateYYYYMMDD(profileData.bDate))
    ) {
      setAreYouSureModalVisible(true);
    } else {
      showToast({
        type: "error",
        title: "ERROR",
        info: "Invalid data",
      });
      setHideErrors(false);
    }
  };



  const onPressIssueCard = () => {
    setAreYouSureModalVisible(false)
    const info = {
      customer_id: userId as number,
      surname: profileData.last,
      amount: profileData.initialB,
      name: profileData.first,
      address_line1: profileData.adress,
      city: profileData.city,
      state: profileData.state,
      country: profileData.country,
      zip: profileData.zipCode,
      phone: profileData.phone,
      email: profileData.email,
      network: cardSetting.network,
      referral_code: "",
    };

    if (cardSetting.type === "Virtual"){
      console.log('123231123123123');
      dispatch(CardsActions.issueVirtualCard.request(info));
    }
    else
    dispatch(
      CardsActions.newHolder.request({
        info,
        gender: profileData.gender as any,
        birth_date: profileData.bDate,
        virtual: cardSetting.type === "Virtual" ? true : undefined
      })
    );
    //() => setStep(2)
  };

  useEffect(() => {
    if(fcfCarRightData && validateName(profileData.first) &&
    validateName(profileData.last) &&
    validateEmail(profileData.email) &&
    validatePhone(profileData.phone) &&
    validateAddress(profileData.adress) &&
    validateCity(profileData.city) &&
    validateState(profileData.state) &&
    validateZipCode(profileData.zipCode) &&
    (cardSetting.type === "Virtual" ||
      validateDateYYYYMMDD(profileData.bDate))){
        setStep(2)
    }
  },[fcfCarRightData])

  return (
    <>
      <Text color={theme.colors.white} size="36px" fontStyle={theme.fonts.f600}>
        {cardName}
      </Text>
      <GridContainer style={{ margin: "30px 0" }}>
        <Item
          variant={"edit"}
          label={t("profile.items.firstName")}
          value={profileData.first}
          id={"first"}
          inputHandler={inputHandler}
          error={hideErrors || validateName(profileData.first)}
        />
        <Item
          variant={"edit"}
          label={t("profile.items.lastName")}
          value={profileData.last}
          id={"last"}
          inputHandler={inputHandler}
          error={hideErrors || validateName(profileData.last)}
        />
        {cardSetting.type !== "Virtual" && (
          <Item
            variant={"edit"}
            label={t("profile.items.gender")}
            //@ts-ignore
            value={GENDER_LABEL[profileData.gender]}
            id={"gender"}
            items={GENDERS_DATA}
            inputHandler={inputHandler}
            setValue={setProfileData}
            select
          />
        )}
        <Item
          variant={"edit"}
          label={t("profile.items.phoneNumber")}
          value={profileData.phone}
          id={"phone"}
          inputHandler={inputHandler}
          error={hideErrors || validatePhone(profileData.phone)}
        />
        <Item
          variant={"edit"}
          label={t("profile.email")}
          value={profileData.email}
          id={"email"}
          inputHandler={inputHandler}
          error={hideErrors || validateEmail(profileData.email)}
        />
        {cardSetting.type !== "Virtual" && (
          <Item
            variant={"edit"}
            label={t("profile.items.bDate")}
            value={profileData.bDate}
            id={"bDate"}
            inputHandler={inputHandler}
            error={hideErrors || validateDateYYYYMMDD(profileData.bDate)}
          />
        )}
        <Item
          variant={"edit"}
          label={t("profile.items.country")}
          rightLabel={countriesData?.countriesTable[profileData.country as any]}
          value={countryText}
          items={countriesData?.countries}
          id={"country"}
          setValue={setProfileData}
          select
        />
        <Item
          variant={"edit"}
          label={t("profile.items.city")}
          value={profileData.city}
          id={"city"}
          inputHandler={inputHandler}
          error={hideErrors || validateCity(profileData.city)}
        />
        <Item
          variant={"edit"}
          label={t("profile.items.state")}
          value={profileData.state}
          id={"state"}
          inputHandler={inputHandler}
          error={hideErrors || validateState(profileData.state)}
        />
        <Item
          variant={"edit"}
          label={t("profile.items.adress")}
          value={profileData.adress}
          id={"adress"}
          inputHandler={inputHandler}
          error={hideErrors || validateAddress(profileData.adress)}
        />
        <Item
          variant={"edit"}
          id={"zipCode"}
          value={profileData.zipCode}
          inputHandler={inputHandler}
          label={"Zip code"}
          error={hideErrors || validateZipCode(profileData.zipCode)}
        />
        <Item
          variant={"edit"}
          id={"refferalID"}
          value={profileData.refferalCode}
          inputHandler={inputHandler}
          label={"Refferal ID"}
          error={true}
        />
        <Item
          variant={"edit"}
          id={"initialB"}
          value={`${profileData.initialB} USD`}
          setValue={setProfileData}
          label={"Initial balance"}
          error={true}
          items={DROPDOWN_DATA_INITIAL_BALANCE}
          select
        />
      </GridContainer>

      <Btn
        buttonActive={profileData.country !== undefined}
        label={t("buttons.submit")}
        type="verification"
        func={handlePressSubmit}
      />
      {areYouSureModalVisible && (
        <AreYouSure
          func={onPressIssueCard}
          no={{ text: t("profile.no"), color: theme.colors.red }}
          yes={{ text: t("profile.yes"), color: theme.colors.green }}
          setModal={setAreYouSureModalVisible}
        />
      )}
    </>
  );
};

export default Inputs;
