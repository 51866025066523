import { restActionCreatorHelper } from './../../helpers/restActionCreatorHelper';

const esimRestAction = restActionCreatorHelper(`esim`);

export const AssignNumberRestActions = esimRestAction('assignNumber');
export const ChangeCallForwardingRestActions = esimRestAction('changeCallForwarding');
export const ChangeRenewStatusRestActions = esimRestAction('changeRenewStatus');
export const GetSmsRestActions = esimRestAction('getSms');
export const ListCountriesRestActions = esimRestAction('listCountries');
export const ListNumbersRestActions = esimRestAction('listNumbers');
export const NewNumberRestActions = esimRestAction('newNumber');
export const SendCodeRestActions = esimRestAction('sendCode');
export const TopUpRestActions = esimRestAction('topUp');
export const UnassignNumberRestActions = esimRestAction('unassignNumber');
export const VerifyCodeRestActions = esimRestAction('verifyCode');
