import React, { useState, useMemo, useEffect } from "react";
import { ItemsContainer, Wrapper } from "./VerificationStyles";
import { Text } from "../../components/Text/Text";
import VerificationItem from "./Item";
import { useThemeContext } from "../../theme";
import i18next from "i18next";
import { userSelector } from "../../logic/user/UserSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerificationModal from "../../components/Modals/Verification";
import DownloadDocuments from "./DownloadDocuments/DownloadDocuments";
import { UserActions } from "../../logic/user/UserRedux";
import { CONFIG } from "../../config";

const Verification = () => {
  const { theme } = useThemeContext();
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const [downloadDocuments, setDownloadDocuments] = useState(false);
  const { t } = i18next;
  const navigate = useNavigate();
  const {
    whoAmI: { data: user },
    enabled2FA,
  } = useSelector(userSelector);

  const onReview = useMemo(() => {
    if (user?.documents?.length) {
      return user?.documents.some((doc) => doc.status === 'Pending') || CONFIG.IS_WHITE_LABEL && user?.documents.some((doc) => doc.status === 'Review');
    }
    return false;
  }, [user]);
  useEffect(() => {
    if (user?.documents) {
      dispatch(UserActions.whoAmI.request());
    }
  }, [downloadDocuments]);

  const navigateWithScroll = () => {
    const state = {
      scrollToTwoSAuth: true,
    };
    navigate("/main/profile", { state });
  };

  return (
    <>
      <Wrapper>
        {downloadDocuments ? (
          <DownloadDocuments setDownloadDocuments={setDownloadDocuments} />
        ) : (
          <>
            <Text
              color={theme.colors.white}
              size="50px"
              fontStyle={theme.fonts.f600}
            >
              {t("verificationLevels.header")}
            </Text>
            <ItemsContainer>
              <VerificationItem
                img="/images/roket1.png"
                title={t("verificationLevels.title1")}
                time={"5"}
                amount="1/3"
                description={t("verificationLevels.desc1")}
                completed={user?.mobile !== ""}
                onPress={() => navigate("/main/profile")}
              />
              <VerificationItem
                img="/images/roket2.png"
                title={t("verificationLevels.title2")}
                time={"10"}
                amount="2/3"
                description={t("verificationLevels.desc2")}
                onReview={onReview}
                completed={user?.status === "Verified"}
                disabled={user?.mobile === ""}
                onPress={() => setDownloadDocuments(!downloadDocuments)}
              />
              <VerificationItem
                img="/images/roket3.png"
                title={t("verificationLevels.title3")}
                time={"5"}
                amount="3/3"
                description={t("verificationLevels.desc3")}
                completed={user?.otp_enabled}
                disabled={user?.mobile === ""}
                onPress={() => navigateWithScroll()}
              />
            </ItemsContainer>
          </>
        )}
      </Wrapper>
      {/* {active && <VerificationModal setVisible={setActive} />} */}
    </>
  );
};
export default Verification;
