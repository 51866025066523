import React, { Children, FC } from "react";
import { Wrapper } from "./ContentBoxStyles";

export type ContentBoxProps = {
  children?: React.ReactNode;
  padding?: string;
  direction?: "row" | "column";
  color?: string;
  backColor?: string;
  margin?: string;
  justyfiContent?: "space-between" | "flex-start" | "flex-end";
  alignItems?: "center" | "flex-start" | "flex-end";
  width?: string;
  border?: string;
  columnGap?: string;
  rowGap?: string;
};

export const ContentBox: FC<ContentBoxProps> = ({
  padding,
  direction,
  color,
  children,
  backColor,
  margin,
  justyfiContent,
  alignItems,
  width,
  border,
  columnGap,
  rowGap,
}) => {
  return (
    <Wrapper
      rowGap={rowGap}
      columnGap={columnGap}
      width={width}
      padding={padding}
      direction={direction}
      color={color}
      backColor={backColor}
      margin={margin}
      justyfiContent={justyfiContent}
      alignItems={alignItems}
      border={border}
    >
      {children}
    </Wrapper>
  );
};
