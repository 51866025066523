import React, { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { ImageCard } from "../CardsStyles";
import styled from "styled-components";
import { CONFIG } from "../../../config";
import { Text } from "../../../components/Text/Text";
import { useThemeContext } from "../../../theme";
import { SkeletonCard } from "../SkeletonLoaders";
import useWindowWidth from "../../../hooks/useWindowWidth";

type Props = {
  setCard: any;
  card: any;
  data: any;
  cardNames: any;
};

const CardSlider: FC<Props> = ({ setCard, card, data, cardNames }) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const windowWidth = useWindowWidth();
  const images = [
    require('../assets/visa_virtual_card.png'),
    require('../assets/master_virtual_card.png'),
    require('../assets/visa_virtual_card.png'),
  ];
  const { theme } = useThemeContext();

  const handleCardClick = (index: any) => {
    setCard(index);
  };

  const handleSlideChange = (swiper: any) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    setCard(swiper.realIndex);
  };

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides
        centeredSlidesBounds
        onSlideChange={handleSlideChange}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
      >
        {images.map((el, index) => (
          <SwiperSlide key={index} style={{ width: "300px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data?.visa_virtual_card ? (
                <ImageCard
                  onClick={() => handleCardClick(index)}
                  src={el}
                  alt={"asd"}
                />
              ) : (
                <SkeletonCard
                  width={windowWidth > 700 ? 350 : 210}
                  height={windowWidth > 700 ? 220 : 132}
                />
              )}

              <Text
                color={theme.colors.white}
                size="20px"
                fontStyle={theme.fonts.f600}
                margin="10px 0 0 0"
              >
                {cardNames[index]}
              </Text>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Previous Button */}
      <Arrow className="swiper-button-prev" variant={isBeginning}>
        <img src="/images/cards/arrow.svg" alt="asd" />
      </Arrow>

      {/* Next Button */}
      <Arrow
        className="swiper-button-next"
        variant={isEnd}
        style={{ right: "16px", left: "auto" }}
        rotate
      >
        <img src="/images/cards/arrow.svg" alt="asd" />
      </Arrow>
    </>
  );
};

export default CardSlider;
export const Arrow = styled.div<{ variant: boolean; rotate?: boolean }>`
  display: ${(p) => (p.variant ? "none" : "block")};
  color: yellow;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: -20%;
  left: 16px;
  width: 50px;
  transform: translateY(-50%) ${(p) => (p.rotate ? "" : "rotate(180deg)")};
  z-index: 2;
  transition: 0.3s;
  &:hover {
    scale: 1.1;
  }
  @media (max-width: 700px) {
    width: 30px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
