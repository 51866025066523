export function parseKeyValuePairs(html) {
    const result = [];
    
    // Регулярное выражение для поиска пар "ключ: значение"
    const regex = /([^:\r\n]+):\s*<b>(.*?)<\/b>/g;
    let match;

    // Итерация по всем найденным совпадениям
    while ((match = regex.exec(html)) !== null) {
        const title = match[1].trim();
        const value = match[2].trim();

        result.push({
            title: title,
            value: value
        });
    }

    return result;
}