import { Instruction, InstructionGroup } from "@appklaar/codebud";
import { CONFIG } from "./../config";
import { UserActions } from "./../logic/user/UserRedux";

export const getUserInstructions = (store: any): (Instruction | InstructionGroup)[] => ([
  {
    groupId: "User",
    groupDescription: "User entity instructions",
    groupColor: "#ff0000",
    groupInstructions: [
      {
        id: "registerEmail",
        description: "First step of registration process",
        parametersDescription: {
          email: "string",
        },
        handler: (data) => {
          store.dispatch(
            UserActions.registerEmail.request({
              email: data.email,
              platform: "iOS"
            })
          );
        },
      },
      {
        id: "confirmNewEmail",
        description: "Second step of registration process",
        parametersDescription: {
          email: "string",
          code: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.confirmNewEmail.request(data));
        },
      },
      {
        id: "register",
        description: "Third step of registration process",
        parametersDescription: {
          first_name: "string",
          last_name: "string",
          customer_type: "number",
          email: "string",
          password: "string",
          country_id: "number",
          referral_code: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.register.request(data));
        },
      },
      {
        id: "login",
        prototype: "login",
        description: "User login",
        parametersDescription: {
          email: "string",
          password: "string",
          otp: "string",
          platform: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.login.request(data));
        },
      },
      {
        id: "loginDefaultTestAccount",
        description: "testt@gg.com",
        handler: () => {
          store.dispatch(
            UserActions.login.request({
              email: "testt@gg.com",
              password: "1212121S",
              platform: "iOS",
            })
          );
        },
      },
      {
        id: "logout",
        prototype: "logout",
        description: "Logout user",
        handler: () => {
          store.dispatch(UserActions.logout.request({}));
        },
      },
      {
        id: "whoAmI",
        description: "Get current logged in user information.",
        handler: () => {
          store.dispatch(
            UserActions.whoAmI.request({ fetchAdditionalInfoOnSuccess: true })
          );
        },
      },
      {
        id: "requestResetPassword",
        description: "Request password reset.",
        parametersDescription: {
          email: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.requestResetPassword.request(data));
        },
      },
      {
        id: "resetPassword",
        description: "Password reset by user.",
        parametersDescription: {
          code: "string",
          new_password: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.resetPassword.request(data));
        },
      },
      {
        id: "getAuthenticatorCode",
        description: "Enable two-step verification with Google Authenticator.",
        handler: () => {
          store.dispatch(UserActions.getAuthenticatorCode.request());
        },
      },
      {
        id: "verifyAuthenticatorCode",
        description: "Confirm two-step verification setup.",
        parametersDescription: {
          otp: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.verifyAuthenticatorCode.request(data));
        },
      },
      {
        id: "disableAuthenticatorCode",
        description: "Disable two-step verification.",
        parametersDescription: {
          otp: "string",
        },
        handler: (data) => {
          store.dispatch(UserActions.disableAuthenticatorCode.request(data));
        },
      },
      {
        id: "setNotifications",
        description: "Set notification types for a user.",
        parametersDescription: {
          id: "number",
          app: "boolean"
        },
        handler: (data) => {
          store.dispatch(UserActions.setNotifications.request({
            id: data.id,
            notification: {
              telegram: false,
              whatsapp: false,
              app: data.app,
              sms: false,
              email: true
            }
          }))
        }
      },
    ]
  },
]);