import {
  addAllRestReducers,
  createRestActions,
  getDefaultRestState,
} from '../../store/restHelper';
import {NodeRestStateType} from '../../store/restHelper.d';
import {ActionReducerMapBuilder, createReducer} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { ChangeRestActions, DeleteRestActions, EditRestActions, ListRestActions, NewRestActions } from './EscrowActions';

/////Types/////

type PositiveResponse = {
  status: boolean
}

export type PartnerStatusType = {
  customer_id: number;
  status: 'Agreed' | string;
}

export type EscrowStatusType = {
  status: 'Cancelled' | string;
}

export type EscrowNewFileType = {
  title: string;
  file: string;
}

export type EscrowTransactionItemType = {
  account_id: number;
  debit: number;
  credit: number
}

export type ChangePayload = {
  id: number;
  type: 'Transaction' | string;
  new_status: EscrowStatusType;
  new_partner_status: PartnerStatusType;
  new_file: EscrowNewFileType;
  new_transaction: EscrowTransactionItemType
}

type DeletePayload = {
  id: number;
}

export type EscrowPartnerItem = {
  customer_id: number;
  customer_email: string;
  customer_name: string;
  account_id: number;
  currency_code: string;
  role: 'Seller' | 'Buyer';
  will_pay: number;
  will_receive: number;
  commission: number;
  total_paid: number;
  total_received: number;
  status: 'AGREED' | 'PENDING' | string;
}

export type EditPayload = {
  id: number;
  title: string;
  description: string;
  partners: EscrowPartnerItem[]
}

export type EscrowFilesType = {
  Key: string;
  Value: string;
}

export type EscrowLogsType = {
  date_time: string;
  text: string;
}

export type ListPayload = {
  customer_id: number;
  id: number
}

export type ListResponse = {
  id: number;
  date_time: string;
  transfer_id: number;
  title: string;
  description: string;
  partners: EscrowPartnerItem[];
  files: EscrowFilesType[]
  status: 'Pending' | string;
}

export type NewEscrowPayload = {
  title: string;
  description: string;
  partners: EscrowPartnerItem[]
}

//////////

const changeRestActions = createRestActions<
  PositiveResponse,
  ChangePayload
>(ChangeRestActions);

const deleteRestActions = createRestActions<
  PositiveResponse,
  DeletePayload
>(DeleteRestActions);

const editRestActions = createRestActions<
  PositiveResponse,
  EditPayload
>(EditRestActions);

const listRestActions = createRestActions<
  ListResponse,
  EditPayload
>(ListRestActions);

const newRestActions = createRestActions<
  PositiveResponse,
  NewEscrowPayload
>(NewRestActions);

const EscrowRestActions = {
  change: changeRestActions,
  delete: deleteRestActions,
  edit: editRestActions,
  list: listRestActions,
  new: newRestActions
};

const EscrowActions = {
  ...EscrowRestActions,
};

type EscrowRestNodes = keyof typeof EscrowRestActions;

const initialRestState = {
  assignNumber: getDefaultRestState<PositiveResponse>(),
  
};

type EscrowState = NodeRestStateType<
EscrowRestNodes, 
 typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<EscrowState>;

const EscrowReducer = createReducer(
  {...initialRestState}, 
  builder =>
    (fp.flow(addAllRestReducers<typeof EscrowRestActions>(EscrowRestActions))(builder) as Builder)
);

export {EscrowReducer, EscrowActions};