export const validateLogin = (login) => /^[\w\@\.\-]{3,100}$/.test(login);

export const validatePassword = (password) =>
  /^(?=.*[A-Z])(?=.*\d)[\w\!\@\#\$\%\^\&\*\(\)\+\=\{\}\[\]\|\:\;\"\'<,>\.\?\-\/\\]{8,32}$/.test(
    password
  );

export const validatePasswordsMatch = (p1, p2) => !!p1 && p1 === p2;

export const validatePasswordMinLength = (password) => password.length >= 8;

export const validatePasswordMaxLength = (password) => password.length <= 32;

export const validateOneNumber = (t) => /\d+/i.test(t);

export const validateOneUppercase = (t) => /[A-Z]+/.test(t);

export const validatePhone = (phone) => /^\d{8,15}$/.test(phone);
export const validateName = (name) =>
  /^[\wа-яА-Яa-zA-Z\sіІєЄґҐїЇ']{3,100}$/.test(name);
export const validateCardLabel = (label) =>
  /^[\wа-яА-Яa-zA-Z\sіІєЄґҐїЇ']{3,100}$/.test(label);

export const validateBtcAddress = (address) =>
  /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);

export const validateEthAddress = (address) =>
  /^0x[a-fA-F0-9]{40}$/.test(address);

export const validateTronAddress = (address) =>
  /^T[a-zA-Z0-9]{33}$/.test(address);

export const validateCryptoAddress = (address) => {
  return (
    validateBtcAddress(address) ||
    validateEthAddress(address) ||
    validateTronAddress(address)
  );
}

export const validateClearingNumber = (number) => /^[0-9]{4,6}/.test(number);
export const validateAccountNumber = (account) => /^[0-9]{2,12}/.test(account);
export const validateQuestion = (question) => /^.{1,100}/.test(question);

export const validateFloat = (num) => /^-?\d*(\.\d{0,18})?$/.test(num);
export const validateInt = (num) => !/\D/.test(num);
export const validateEmail = (email) =>
  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email);

export const validateApiKey = (key) => /^[a-z0-9]{16}$/.test(key);

export const validateOtpCode = (code) => /^\d{6}$/.test(code);

export const passwordValidations = {
  validatePassword,
  validatePasswordsMatch,
  validateOneNumber,
  validateOneUppercase,
  validatePasswordMinLength,
  validatePasswordMaxLength,
};

export const validate2FACode = (code) => /^\d{6}$/.test(code);

export const couldBeVisa = (s) => /^4/.test(s);

export const couldBeMaster = (s) => /^5[1-5]/.test(s) || /^2[2-7]/.test(s);

export const validateCardNumberVisa = (s) =>
  /^(?:4[0-9]{12}(?:[0-9]{3})?)$/.test(s);

export const validateCardNumberMaster = (s) =>
  /^(?:5[1-5][0-9]{14})$/.test(s) || /^(?:2[2-7][0-9]{14})$/.test(s);

export const validateCardNumberVisaOrMaster = (s) =>
  validateCardNumberVisa(s) || validateCardNumberMaster(s);

export const validateBankName = (s) => s.length >= 2;

export const validateBankAccountNumber = (s) => s.length >= 4 && s.length <= 40;

export const validateBIC = (s) => /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(s);

export const validateIBAN = (s) => /^[a-zA-Z0-9]+$/.test(s);

export const validateZipCode = (s) => /^[0-9]{5}(?:-[0-9]{5})?$/.test(s);

export const validateIdNumber = (s) => s.length > 1 && s.length < 100;

export const validateDateYYYYMMDD = (s) =>
  /^\d{4}\/\d{2}\/\d{2}$/.test(s) || /^\d{4}-\d{2}-\d{2}$/.test(s);

export const validateDateMMDDYYYY = (s) => /^\d{2}\/\d{2}\/\d{4}$/.test(s);

export const validateAddress = (s) => s.length > 3 && s.length < 200;

export const validateCity = (s) => s.length > 0 && s.length < 200;

export const validateState = (s) => s.length >= 5 && s.length <= 50;

export const validateNftUsername = (s) =>
  s.length > 0 && /^[A-Za-z0-9]*$/.test(s);

export const validateNftBio = (s) => s.length > 3 && s.length < 100;

export const validateLink = (s) =>
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(
    s
  );

export const validateLinkAllowEmpty = (s) => s.length === 0 || validateLink(s);

export const validateNftCollectionTitle = (s) => s.length > 3 && s.length < 30;

export const validateNftCollectionDescription = (s) =>
  s.length > 4 && s.length <= 100;

export const validateNftTitle = (s) => s.length > 3 && s.length < 30;

export const validateNftDescription = (s) => s.length > 4 && s.length <= 100;

export const validateNftContractName = (s) => s.length > 1 && s.length < 30;

export const validateNftContractSymbol = (s) => s.length > 1 && s.length < 30;

export const validateFiatDepositAddress = (s) => /^ZP\d{4,10}[A-Z]{2,5}$/.test(s);